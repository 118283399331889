<template>
  <el-card class="cms-banner card card__main">
    <div class="text-right mb-4">
      <el-button type="primary" @click="handleClickTag()">新增</el-button>
    </div>

    <Table
      :data="list.data"
      :cols="list.cols"
      :page="list"
      v-loading="list.loading"
      @page="handlePage"
      @current-change="currentChange"
      @size-change="sizeChange"
    >
      <template #thumb>
        >
        <el-table-column label="客户">
          <template #default="scope">
            <div>
              {{ scope.row.customer.nickName }}
            </div>
          </template>
        </el-table-column>
      </template>
      <template #press>
        >
        <el-table-column label="预约时间">
          <template #default="scope">
            <span>{{ scope.row.finished }}</span>
          </template>
        </el-table-column>
      </template>
      <template #star>
        >
        <el-table-column label="服务时段">
          <template #default="scope">
            <span
              >{{ scope.row.servDay }}<br />
              {{ scope.row.servBegin }} - {{ scope.row.servEnd }}</span
            >
          </template>
        </el-table-column>
      </template>
      <template #dig>
        >
        <el-table-column label="客户评价">
          <template #default="scope">
            <span>{{ scope.row.cusDig == 1 ? "已评价" : "未评价" }}</span>
          </template>
        </el-table-column>
      </template>
      <template #state>
        >
        <el-table-column label="状态">
          <template #default="scope">
            <span>{{ scope.row.state }}</span>
          </template>
        </el-table-column>
      </template>
      <template #action>
        >
        <el-table-column label="操作" width="180">
          <template #default="scope">
            <el-button type="text" @click="handleClickEdit(scope.row)"
              >编辑</el-button
            >
            <el-button type="text" @click="handleClickDelete(scope.row)"
              >删除</el-button
            >
            <el-popconfirm
              v-if="scope.row.state == '待开始'"
              class="pl-2"
              confirm-button-text="确认"
              cancel-button-text="取消"
              icon="el-icon-info"
              icon-color="red"
              title="是否确定开始服务？"
              @confirm="restart(scope.row)"
            >
              <el-button type="text" slot="reference">开始服务</el-button>
            </el-popconfirm>
            <el-popconfirm
              class="pl-2"
              v-if="scope.row.state == '服务开始'"
              confirm-button-text="确认"
              cancel-button-text="取消"
              icon="el-icon-info"
              icon-color="red"
              title="是否确定完成服务？"
              @confirm="handleClickApproval(scope.row)"
            >
              <el-button class="pl-2" type="text" slot="reference"
                >完成服务</el-button
              >
            </el-popconfirm>
          </template>
        </el-table-column>
      </template>
    </Table>
  </el-card>
</template>

<script>
import {
  addSettingTag,
  deleteSettingTag,
  getSettingTags,
  modifySettingTag,
} from "@/api/tag";
import Table from "@/components/Table/Table";
export default {
  name: "getLists",
  components: { Table },
  data() {
    return {
      list: {
        pageNo: 1,
        pageSize: 10,
        count: 0,
        payOrder: "",
        time: [],
        state: "",
        type: 2,
        data: [],
        cols: [
          // { key: "press" },
          { key: "created", label: "创建时间" },
          { key: "tag", label: "标签" },
          {
            label: "操作",
            key: "action",
            width: 200,
          },
        ],
        loading: false,
      },
      stateList: {
        0: "待付款",
        1: "预约成功",
        2: "待开始",
        3: "服务已开始",
        4: "客户爽约",
        5: "咨询师爽约",
        6: "服务已结束",
        8: "已完成",
        9: "已终止",
      },

      dialoading: false,
    };
  },
  mounted() {
    this.getLists();
  },

  methods: {
    handleClickNote(e) {
      e;
      console.log(this.$refs);
      this.$refs.note.openDialog(e.id);
    },
    async getLists(isSearch) {
      this.list.loading = true;
      if (isSearch) {
        this.list.pageNo = 1;
      }
      console.log(this.list.state);
      let params = {
        page: this.list.pageNo,
        size: this.list.pageSize,
      };
      let res = await getSettingTags(params);
      if (res && res.code === 0 && res.data.records) {
        this.list.data = res.data.records;
        this.list.count = res.data.count;
      } else {
        this.list.data = [];
      }
      this.list.loading = false;
    },
    handlePage(v) {
      this.list.pageNo = v;
      this.getLists();
    },
    currentChange(v) {
      this.list.pageNo = v;
      this.getLists();
    },
    sizeChange(v) {
      this.list.pageNo = 1;
      this.list.pageSize = v;
      this.getLists();
    },
    //查询详情
    async handleClickEdit(data) {
      this.handleClickTag(data.id, data.tag);
    },

    handleClickTag(id, tag) {
      var isNew = true;
      if (id) isNew = false;
      this.$prompt("请输入邮箱", (isNew ? "新增" : "编辑") + "评价标签", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue: isNew ? "" : tag,
        inputErrorMessage: "标签不得为空",
      })
        .then(async ({ value }) => {
          var res;
          if (isNew) {
            console.log(value);

            res = await addSettingTag(value);
          } else {
            res = await modifySettingTag({ id, newTag: value });
          }
          if (res.code == 0) {
            this.$message.success("操作成功");
            this.getLists();
          } else {
            this.$message.error("操作失败");
          }
        })
        .catch(() => {});
    },
    async handleClickDelete(e) {
      this.$confirm("是否删除", "确认删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        var res = await deleteSettingTag(e.id);
        if (res.code == 0) {
          this.$message.success("操作成功");
          this.getLists();
        } else {
          this.$message.error("操作失败");
        }
      });
    },
  },
};
</script>

<style>
.cms-banner .el-image-viewer__close {
  color: #fff;
  font-size: 50px;
}
</style>
