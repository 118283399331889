<template>
  <el-dialog
    width="900px"
    :visible.sync="parentData.show"
    title="咨询师详情"
    top="3vh"
    :before-close="handleClickClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-card>
      <el-row :gutter="20">
        <el-col :span="5">
          <div class="block">
            <el-image :src="parentData.data.profile.avatar">
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image>
          </div>
        </el-col>
        <el-col :span="19">
          <el-row :gutter="20">
            <el-col :span="24" class="mt-2">
              <div>
                <span class="tlabel">姓名：</span>
                <span>{{ parentData.data.profile.realName }}</span>
              </div>
            </el-col>
            <el-col :span="24" class="mt-2">
              <el-row>
                <el-col :span="12">
                  <div>
                    <span class="tlabel">级别：</span>
                    <span>{{ parentData.data.role.roleName }}</span>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="flex">
                    <span class="tlabel">星级：</span>
                    <el-rate
                      disabled-void-icon-class="el-icon-star-off"
                      disabled-void-color="#c0c4cc"
                      :icon-classes="iconClasses"
                      :colors="['#ff4e4e', '#ff4e4e', '#ff4e4e']"
                      v-model="parentData.data.profile.level"
                      disabled
                    >
                    </el-rate>
                  </div>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="24" class="mt-2">
              <el-row>
                <el-col :span="12">
                  <div>
                    <span class="tlabel">案例数：</span>
                    <span>{{ parentData.data.profile.caseCount }}</span>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div>
                    <span class="tlabel">服务费用：</span>
                    <span>{{ parentData.data.profile.cost }}</span>
                  </div>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="24" class="mt-2">
              <el-row>
                <el-col :span="12">
                  <div>
                    <span class="tlabel">联系电话：</span>
                    <span>{{ parentData.data.profile.phone }}</span>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div>
                    <span class="tlabel">提成比例：</span>
                    <span>{{ parentData.data.profile.distr }}%</span>
                  </div>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="24" class="mt-2">
              <div>
                <span class="tlabel">导师评语：</span>
                <span>{{ parentData.data.profile.evalTeacher }}</span>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-card>
    <div class="flex justify-start">
      <div style="width: 100%">
        <el-card>
          <div style="font-size: 20px">咨询预约</div>
          <div
            class="overflow-auto mt-2"
            style="height: 400px"
            v-if="infodata.length > 0"
          >
            <div
              class="list-style"
              v-for="item in infodata"
              :key="item.id"
              style="border-bottom: 1px solid rgb(187, 187, 187)"
            >
              <div class="flex justify-between p-2 item-style">
                <div class="">
                  {{ item.servDay }} {{ item.servBegin }}-{{ item.servEnd }}
                </div>
                <div class="pl-2 pr-2">{{ item.customer.nickName }}的预约</div>
                <div class="">
                  <div>
                    {{ item.state }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="overflow-auto mt-2" style="height: 400px">暂无预约</div>
        </el-card>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { getLists } from "@/api/serviceFlow";
export default {
  emits: ["offIt"],
  props: {
    parentData: {},
  },
  data() {
    return {
      tree: [],
      form: {},
      iconClasses: ["el-icon-star-on", "el-icon-star-on", "el-icon-star-on"],
      infodata: [],
    };
  },
  mounted() {
    this.getdata();
  },
  methods: {
    //获取预约信息
    async getdata() {
      let params = {
        consultor: this.parentData.data.id,
        begin: "-",
        end: "-",
        state: "-",
        history: "-",
        cusDig: "-",
        teaDig: "-",
        payOrder: "-",
        pageNo: 1,
        pageSize: 20,
      };
      let res = await getLists(params);
      if (res && res.code === 0 && res.data.records) {
        this.infodata = res.data.records;
      } else {
        this.infodata = [];
      }
    },
    //关闭窗口
    async handleClickClose() {
      this.$emit("offIt");
    },
  },
};
</script>
<style scoped>
.el-form .el-form-item {
  margin-bottom: 5px !important;
}
.tlabel {
  width: 80px;
  font-weight: 700;
}

/* 偶数行的样式 */
.list-style:nth-child(odd) > .item-style {
  background-color: rgb(236, 235, 235);
}
</style>
