<template>
  <div class="error-page">
    <el-card class="main">
      <div class="error">
        <img :src="error[errorKey]['src']" alt="" />
        <p class="error-msg">{{ errorKey }}:{{ error[errorKey]["text"] }}</p>
      </div>
    </el-card>
  </div>
</template>

<script>
const img_404 = require("@/assets/404.png");
const img_403 = require("@/assets/403.png");

export default {
  name: "error-page",
  data() {
    return {
      error: {
        404: {
          src: img_404,
          text: "您访问的路径不存在"
        },
        403: {
          src: img_403,
          text: "权限不足"
        }
      }
    };
  },
  computed: {
    errorKey() {
      return this.$route.name || "404";
    }
  }
};
</script>

<style lang="scss" scoped>
.main {
  height: 80vh;
  position: relative;
  .error {
    text-align: center;
    &-msg {
      color: #999999;
      font-size: 24px;
      position: absolute;
      bottom: 25%;
      left: 20px;
      width: calc(100% - 40px);
      text-align: center;
    }
  }
}
</style>
