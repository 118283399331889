<template>
  <div>
    <el-dialog
      title="资讯笔记"
      :visible.sync="show"
      width="800px"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <div v-show="showType == 'LIST'">
        <div class=" mb-4 text-right">
          <el-button type="success" @click="handleClickNew">新增</el-button>
        </div>

        <el-table :data="list" style="width: 100%" v-loading="loading">
          <el-table-column prop="created" label="创建时间" width="200">
          </el-table-column>
          <el-table-column prop="content" label="内容"> </el-table-column>
          <el-table-column prop="address" label="操作" width="80">
            <template #default="scope">
              <el-button
                type="text"
                size="small"
                @click="handleClickDelete(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="mt-4 text-right">
          <el-button @click="show = false">关闭</el-button>
        </div>
      </div>
      <div v-show="showType == 'INFO'">
        <div class=" mb-4 text-left">
          <el-button icon="el-icon-back" size="small" @click="handleClickBack"
            >返回</el-button
          >
        </div>
        <div class=" mb-2">
          笔记内容：
        </div>
        <div class="form">
          <el-input
            type="textarea"
            placeholder="请输入笔记内容"
            v-model="note.content"
            rows="8"
            :disabled="note.id != null"
            maxlength="500"
            show-word-limit
          >
          </el-input>
        </div>
        <div class="mt-4 text-right">
          <el-button type="primary" @click="submitAdd" :loading="loading"
            >确认新增</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addNote, deleteNote, getNoteList } from "@/api/note";

export default {
  name: "MyOrderNote",
  data() {
    return {
      // 服务id
      id: "",
      // LIST、INFO
      showType: "LIST",
      show: false,
      list: [],
      loading: false,
      note: {
        content: "",
      },
    };
  },
  methods: {
    async getList(id) {
      this.loading = true;
      var res = await getNoteList(id);
      this.loading = false;
      if (res.code === 0) {
        this.list = res.data;
      }
    },
    // 传入案例id
    openDialog(id) {
      this.show = true;
      this.showType = "LIST";
      this.id = id;
      this.getList(id);
    },
    handleClose() {
      this.show = false;
    },
    handleClickBack() {
      this.showType = "LIST";
      this.note = {};
    },
    handleClickNew() {
      this.showType = "INFO";
      this.note = {};
    },
    async handleClickDelete(e) {
      var res = await deleteNote(e.id);
      if (res.code == 0) {
        this.$message.success("操作成功");
        this.getList(this.id);
      } else {
        this.$message.success(res.message);
      }
    },
    async submitAdd() {
      this.loading = true;
      var res = await addNote({ content: this.note.content, noteId: this.id });
      this.loading = false;
      if (res.code == 0) {
        this.$message.success("操作成功");
        this.handleClickBack();
        this.getList(this.id);
      } else {
        this.$message.success(res.message);
      }
    },
  },
};
</script>

<style></style>
