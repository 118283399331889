
import PubList from "@/views/Serves/Pubs/PubList.vue";
import Order from "@/views/Serves/Order/OrderList.vue";
import MyOrder from "@/views/Serves/MyOrder/MyOrderList.vue";

export default [
  {
    path: "PubList",
    name: "PubList",
    component: PubList,
    meta: {
      title: "管理后台",
      path: [{ name: "管理后台" }, { name: "咨询师管理" }]
    }
  },
  {
    path: "Order",
    name: "Order",
    component: Order,
    meta: {
      title: "管理后台",
      path: [{ name: "管理后台" }, { name: "咨询预约" }]
    }
  },
  {
    path: "MyOrder",
    name: "MyOrder",
    component: MyOrder,
    meta: {
      title: "管理后台",
      path: [{ name: "管理后台" }, { name: "我的预约" }]
    }
  },
  
];
