<template>
  <el-card class="cms-banner card card__main">
    <div class="c-search">
      <div class="c-search-params">
        <el-input
          class="text_input"
          v-model="list.payOrder"
          placeholder="订单号"
        ></el-input>
        <el-date-picker
          v-model="list.time"
          type="datetimerange"
          range-separator="至"
          start-placeholder="预约开始日期"
          end-placeholder="预约结束日期"
          value-format="yyyy-MM-DD HH:mm"
        >
        </el-date-picker>
        <el-select v-model="list.state" clearable placeholder="服务状态">
          <el-option
            v-for="(item, index) in stateList"
            :key="index"
            :label="item"
            :value="index"
          >
          </el-option>
        </el-select>
        <el-button
          @click="getLists(true)"
          class="button"
          size="medium"
          type="primary"
        >
          <img
            width="20"
            src="../../../assets/organization/chaxun.png"
            alt=""
          />
        </el-button>
      </div>
      <div class="c-search-btns">
        <!-- <el-button type="warning">批量导入</el-button> -->
        <!-- <el-button type="primary" @click="handleClickEdit" icon="el-icon-plus">
          添加咨询师
          <i class="iconfont iconzengjia right"></i>
        </el-button> -->
      </div>
    </div>
    <Table
      :data="list.data"
      :cols="list.cols"
      :page="list"
      v-loading="list.loading"
      @page="handlePage"
      @current-change="currentChange"
      @size-change="sizeChange"
    >
      <!-- <div v-slot:test='1'>123</div> -->

      <template #thumb>
        >
        <el-table-column label="参与人">
          <template #default="scope">
            <div>
              {{ scope.row.customer.nickName }} --
              {{ scope.row.servicer.profile.realName }}
            </div>
          </template>
        </el-table-column>
      </template>
      <template #press>
        >
        <el-table-column label="预约时间">
          <template #default="scope">
            <span>{{ scope.row.finished }}</span>
          </template>
        </el-table-column>
      </template>
      <template #star>
        >
        <el-table-column label="服务时段">
          <template #default="scope">
            <span
              >{{ scope.row.servDay }}<br />
              {{ scope.row.servBegin }} - {{ scope.row.servEnd }}</span
            >
          </template>
        </el-table-column>
      </template>
      <template #dig>
        >
        <el-table-column label="客户评价">
          <template #default="scope">
            <span>{{ scope.row.cusDig ? "已评价" : "未评价" }}</span>
          </template>
        </el-table-column>
      </template>
      <template #state>
        >
        <el-table-column label="状态">
          <template #default="scope">
            <span>{{ scope.row.state }}</span>
          </template>
        </el-table-column>
      </template>
      <template #action>
        >
        <el-table-column label="操作" width="180">
          <template #default="scope">
            <el-button type="text" @click="handleClickEdit(scope.row)"
              >详情</el-button
            >
            <el-button
              @click="handleClickRemove(scope.row)"
              type="text"
              v-if="!scope.row.teaDig"
              >导师评价</el-button
            >
          </template>
        </el-table-column>
      </template>
    </Table>
    <OrderInfo
      v-if="parentData.show"
      :parentData="parentData"
      @offIt="offDia"
    ></OrderInfo>
    <el-dialog
      title="提交评价"
      v-loading="dialoading"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <div>
        评分：<el-rate v-model="credit"></el-rate>
        导师评价：
        <el-input
          size="small"
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 4 }"
          v-model="intro"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="changeState(true)">确认</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import { getLists, flowRecommend, getFlowInfo } from "@/api/serviceFlow";
import Table from "@/components/Table/Table";
import OrderInfo from "../MyOrder/MyOrderForm.vue";
export default {
  name: "getLists",
  components: { Table, OrderInfo },
  data() {
    return {
      list: {
        pageNo: 1,
        pageSize: 10,
        count: 0,
        payOrder: "",
        time: [],
        state: "",
        type: 2,
        data: [],
        cols: [
          {
            key: "thumb",
          },
          // { key: "press" },
          { key: "star" },
          { key: "dig" },
          { key: "state" },
          // { key: "name", label: "人类图类型" },
          {
            label: "操作",
            key: "action",
            width: 200,
          },
        ],
        loading: false,
      },
      stateList: {
        0: "待付款",
        1: "预约成功",
        2: "待开始",
        3: "服务已开始",
        4: "客户爽约",
        5: "咨询师爽约",
        6: "服务已结束",
        8: "已完成",
        9: "已终止",
      },
      dialogVisible: false,
      diaData: {},
      type: 1, //type	类型0=电子期刊1=电子年鉴2=图书 ,示例值(1)
      typeList: [
        {
          id: 1,
          name: "解读师",
        },
        {
          id: 2,
          name: "认证解读师",
        },
        {
          id: 3,
          name: "咨询师",
        },
        {
          id: 4,
          name: "认证咨询师",
        },
      ],
      addDialog: {
        show: false,
        data: {},
        tree: [],
        title: "",
      },
      credit: 0,
      intro: "",
      parentData: {
        show: false,
        data: {},
      },
      dialoading: false,
    };
  },
  mounted() {
    this.getLists();
  },

  methods: {
    async getLists(isSearch) {
      if (isSearch) {
        this.list.pageNo = 1;
      }
      console.log(this.list.state);
      let params = {
        consultor: "-",
        begin:
          this.list.time && this.list.time.length > 0 ? this.list.time[0] : "-",
        end:
          this.list.time && this.list.time.length > 0 ? this.list.time[1] : "-",
        state: this.list.state == "" ? "-" : this.list.state,
        history: "-",
        cusDig: "-",
        teaDig: "-",
        payOrder: this.list.payOrder == "" ? "-" : this.list.payOrder,
        pageNo: this.list.pageNo,
        pageSize: this.list.pageSize,
      };
      let res = await getLists(params);
      if (res && res.code === 0 && res.data.records) {
        this.list.data = res.data.records;
        this.list.count = res.data.count;
      } else {
        this.list.data = [];
      }
      this.list.loading = false;
    },
    handlePage(v) {
      this.list.pageNo = v;
      this.getLists();
    },
    currentChange(v) {
      this.list.pageNo = v;
      this.getLists();
    },
    sizeChange(v) {
      this.list.pageNo = 1;
      this.list.pageSize = v;
      this.getLists();
    },
    async handleClickEdit(data) {
      this.list.loading = true;
      let res = await getFlowInfo(data);
      if (res.code === 0) {
        console.log(res.data);
        this.parentData.data = res.data;
        this.parentData.show = true;
      }
      this.list.loading = false;
    },
    //关闭窗口
    async offDia(data) {
      this.addDialog.show = false;
      this.infoDialog.show = false;
      if (data) {
        this.getTableList();
      }
    },
    handleClickRemove(row) {
      this.diaData = row;
      this.credit = "";
      this.intro = "";
      this.dialogVisible = true;
    },
    async changeState() {
      this.dialoading = true;
      let params = {
        id: this.diaData.id,
        credit: this.credit,
        intro: this.intro,
        noteId: this.diaData.id,
      };
      let res = await flowRecommend(params);
      if (res.code === 0) {
        this.$message.success("评论成功！");
        this.getLists();
        this.dialogVisible = false;
      } else {
        this.$message.warning(res.message);
      }
      this.dialoading = false;
    },
  },
};
</script>

<style>
.cms-banner .el-image-viewer__close {
  color: #fff;
  font-size: 50px;
}
</style>
