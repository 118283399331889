import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import router from "./router";
import "./theme.scss";
import dayjs from "dayjs";
Vue.use(ElementUI, { zIndex: 10 });
Vue.prototype.dayjs = dayjs;
Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
