<template>
  <el-menu :router="true" :default-active="defaultActive">
    <menu-item :menuList="menu"></menu-item>
  </el-menu>
</template>
<script>
import menu from "@/views/menu.js";
import MenuItem from "./MenuItem.vue";

export default {
  name: "Menu",
  components: {
    MenuItem
  },
  data() {
    return {
      menu: [],
      sysMenus: JSON.parse(localStorage.getItem("menus")),
      defaultActive: "",
      userRole: JSON.parse(localStorage.RLTuser).roleName
    };
  },

  created() {
    this.initMenu();
    // TODO 处理默认展开
    this.defaultActive = this.$route.fullPath;
  },
  mounted() {},
  computed: {},
  methods: {
    initMenu() {
      this.checkPermission(menu);
      console.log(menu, this.sysMenus);
      this.menu = menu;
    },
    checkPermission(data) {
      data.forEach(menu => {
        menu.permission = false;
        const sysMenus = this.sysMenus;
        for (let i = 0; i < sysMenus.length; i++) {
          if (sysMenus[i].url == menu.path) {
            menu.permission = true;
          }
        }
        if (menu.children) {
          this.checkPermission(menu.children);
        }
      });
    },
    test() {}
  }
};
</script>

<style></style>
