import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Serve from "./serve"; // 内容管理
import System from "./system"; // 系统管理
import ErrorPage from "@/views/ErrorPage";
import Index from "../views/Index.vue"; //首页
import Code from "@/views/Code.vue";
import Advert from "@/views/Advert/Advert.vue";
import Distributor from "@/views/Distributor/Distributor.vue";
import DesignExport from "@/views/DesignExport/DesignExport";
import { Message } from "element-ui";
Vue.use(VueRouter);
const routes = [
  {
    path: "/login",
    component: Login,
    name: "login"
  },
  {
    path: "/",
    component: Home,
    children: [
      ...Serve,
      ...System,
      {
        path: "/DesignExport",
        name: "DesignExport",
        component: DesignExport,
        meta: {
          title: "人类图导出",
          path: [{ name: "管理后台" }, { name: "人类图导出" }]
        }
      },
      {
        path: "/Distributor",
        name: "Distributor",
        component: Distributor
      },
      {
        path: "/code",
        component: Code,
        name: "Code"
      },
      {
        path: "/Advert",
        component: Advert,
        name: "advert"
      },
      {
        path: "",
        component: Index,
        name: "Index"
      },
      {
        path: "403",
        component: ErrorPage,
        name: "403"
      },
      {
        path: "*",
        component: ErrorPage,
        name: "404"
      }
    ]
  }
];
const router = new VueRouter({
  // mode: "history",
  routes
});
router.beforeEach((to, from, next) => {
  let token = localStorage.getItem("RLTToken");
  let expire = localStorage.getItem("RLTTokenExpire");
  let e = new Date().getTime() - expire;
  const notTimeOut = token && e < 0;
  if (notTimeOut || to.path === "/Login") {
    next();
  } else {
    next("/Login");
    if (from.fullPath === "/") {
      console.log("is_not_login");
    } else if (e < 0) {
      Message.info("用户信息超时，请重新登录");
    } else {
      Message.error("未登录");
    }
  }
});
export default router;
