import { httpInit } from "./http";

const http = httpInit("/consulting/service");
const http1 = httpInit("/star-calendar/human_design");

//分页查询服务列表
// begin	服务开始时间下限,示例值(2021-02-01 09:00)
// consultor	咨询师ID,示例值(1)
// cusDig	客户评价标识,可用值:0,1,示例值(-)
// end	服务开始时间上限,示例值(2021-03-01 10:30)
// history	人类图记录ID,示例值(80177)
// page	页码,示例值(1)
// payOrder	支付订单号，支持模糊匹配,示例值(-)
// size	分页长度,示例值(10)
// state	服务状态:
// 0: 待咨询师确认(ConsultNoteState::sconfirm)
// 1: 预约成功(ConsultNoteState::sconfirm)
// 2: 待开始(ConsultNoteState::sconfirm)
// 3: 服务已开始(ConsultNoteState::sconfirm)
// 4: 客户爽约(ConsultNoteState::sconfirm)
// 5: 咨询师爽约(ConsultNoteState::sconfirm)
// 6: 待客户评价(ConsultNoteState::sconfirm)
// 7: 待导师评价(ConsultNoteState::sconfirm)
// 8: 已完成(ConsultNoteState::sconfirm)
// 9: 已终止(ConsultNoteState::sconfirm)
// ,可用值:-,0,1,2,3,4,5,6,7,8,9,示例值(-)
// teaDig	导师评价标识,可用值:0,1,示例值(-)
export const getLists = params =>
  http.get(
    `/list/${params.consultor}/${params.begin}/${params.end}/${params.state}/${params.history}/${params.cusDig}/${params.teaDig}/${params.payOrder}/${params.pageNo}/${params.pageSize}`
  );

//客户签到咨询服务
export const flowCheckIn = params => http.put(`/flow/check_in/${params.id}`, params);

//开始咨询服务，仅咨询师可使用
export const flowStart= params => http.put(`/flow/start/${params.id}`, params);

//咨询服务结束，仅咨询师可使用
export const flowFinish = params => http.put(`/flow/finish/${params.id}`, params);

//取消预约，据预约开始时间少于2小时时不能取消
export const flowUnSubscribe= params => http.put(`/flow/subscribe/${params.id}`, params);

//支付咨询服务费
export const flowPay = params => http.put(`/flow/pay/${params.id}/${params.orderCode}/${params.amount}`, params);

//服务评价
export const flowRecommend = params => http.post(`/flow/recommend/${params.id}`, params);

//预约咨询服务
export const flowSubscribe = params => http.post(`/flow/subscribe`, params);

//查询指定服务的详情
export const getFlowInfo = params => http.get(`/info/${params.id}`);

//查询指定咨询师未来7天内的可预约时段
export const getTimepoint = params => http.get(`/timepoint/${params.id}`);

//管理后台根据ID查询微信用户的历史记录
export const getBackInfo = params => http1.get(`/back/info/${params.id}`);