import { httpInit } from "./http";

const http = httpInit("/consulting/tag");




export const getSettingTags = params =>http.get(`page/${params.page}/${params.size}`)


export const addSettingTag = (tag)=> http.post(`info`,{tag});

export const modifySettingTag = ({id,newTag})=>http.put(`info/${id}`,{newTag});

export const deleteSettingTag = id =>http.delete(`info/${id}`);
