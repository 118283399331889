<template>
  <div>
    <el-card>
      <p>点击日期添加/编辑可咨询时段：</p>
      <div style="width: 100%; height: 100%">
        <FullCalendar
          style="width: 100%; height: 100%"
          :options="calendarOptions"
          locale="zh-cn"
        />
      </div>
    </el-card>
    <el-dialog :title="dialog.title" :visible.sync="dialog.show">
      <div class="list"></div>
      <el-form
        ref="form"
        :model="form"
        label-width="120px"
        label-position="left"
      >
        <el-form-item label="已设置时段:">
          <div class=" flex">
            <p v-for="item in dialog.list" :key="item.id" class=" mr-2 mb-2">
              <el-tag closable @close="removeEvent(item.id)">
                {{ item.startTime }}- {{ item.stopTime }}
              </el-tag>
            </p>
          </div>
        </el-form-item>

        <el-form-item label="新增服务时段:">
          <el-time-picker
            is-range
            :clearable="false"
            v-model="form.time"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围"
            value-format="HH:mm:ss"
          >
          </el-time-picker>
          <el-button type="primary" @click="onSubmit" class=" ml-2"
            >新增</el-button
          >
        </el-form-item>

        <el-form-item> </el-form-item>
      </el-form>
      <div class=" text-center m-2">
        <el-button @click="dialog.show = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { settingSet, getSettingInfo, removeSettingInfo } from "@/api/setting";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import "@fullcalendar/core/locales/zh-cn";
import dayjs from "dayjs";

export default {
  name: "Appointment",
  components: {
    FullCalendar,
  },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: "dayGridMonth",
        buttonText: {
          today: "今天",
        },
        headerToolbar: {
          // 头部toolba
          left: "",
          center: "title",
          right: "prev,today,next",
          // right: 'dayGridMonth'
        },

        dateClick: this.handleDateClick,
        locale: "zh-cn",
        events: [],
        eventClick: this.handleEventClick,
        // eventBackgroundColor: "red",
        // eventTextColor:"green"
        // eventColor: "green",
        eventTimeFormat: {
          // like '14:30:00'
          meridiem: "short",
        },
      },
      dialog: {
        show: false,
        title: "设置",
        list: [],
      },
      list: {},
      form: {
        time: ["09:00", "18:00"],
      },
    };
  },
  mounted() {
    this.getSetting();
  },
  methods: {
    handleEventClick(e) {
      const { event } = e;
      this.handleDateClick({ dateStr: event.startStr });
    },
    handleDateClick(e) {
      const { dateStr } = e;
      const key = dayjs(dateStr).format("YYYY-M-D");
      var dayEvents = this.list[key];
      this.dialog.key = e;
      if (dayEvents && dayEvents.length) {
        this.dialog.list = dayEvents;
      } else {
        this.dialog.list = [];
      }

      this.dialog.title = `${dateStr} 可预约时段设置`;
      this.dialog.show = true;
      this.dialog.dateStr = dateStr;
      this.form.time = ["09:00:00", "18:00:00"];
    },

    addEvent(e) {
      const { state } = e;
      var colors = {
        0: "#A9EA7A",
        1: "#448EF7",
        3: "gray",
      };
      var stateLable = {
        0: "待预约",
        1: "待支付",
        2: "已预约",
        3:'已结束'
      };
      this.calendarOptions.events.push({
        id: e.id,
        title: `${e.startTime} - ${e.stopTime} ${stateLable[state]}`,
        start: e.begin,
        end: e.end,
        allDay: true,
        color: colors[state],
      });
    },
    async onSubmit() {
      let params = {
        servBegin: this.dialog.dateStr + " " + this.form.time[0],
        servEnd: this.dialog.dateStr + " " + this.form.time[1],
      };
      let res = await settingSet(params);
      if (res.code === 0) {
        this.$message.success("保存成功！");
        await this.getSetting();
        this.$nextTick(() => {
          this.handleDateClick(this.dialog.key);
        });
      } else {
        this.$message.error("保存失败！" + res.message);
      }
    },
    async removeEvent(id) {
      let res = await removeSettingInfo(id);
      if (res.code === 0) {
        this.$message.success("操作成功");
        // this.dialog.show = false;
        var index = this.dialog.list.findIndex((v) => v.id == id);
        this.dialog.list.splice(index, 1);
        await this.getSetting();
      } else {
        this.$message.error("失败！" + res.message);
      }
    },
    async getSetting() {
      let res = await getSettingInfo();
      this.calendarOptions.events = [];
      if (res.code === 0) {
        this.list = res.data;
        for (const key in res.data) {
          if (Object.hasOwnProperty.call(res.data, key)) {
            const day = res.data[key];
            if (day && day.length) {
              day.forEach((e) => this.addEvent(e));
            }
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.week {
  text-align: center;
}
</style>
