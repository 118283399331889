<template>
  <el-dialog
    width="600px"
    :visible.sync="parentData.show"
    title="用户详情"
    :before-close="handleClickClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-card>
      <el-row :gutter="20">
        <el-col :span="6">
          <div class="block">
            <el-image :src="src">
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image>
          </div>
        </el-col>
        <el-col :span="18">
          <el-row :gutter="20">
            <el-col :span="24" class="mt-2">
              <div>
                <span class="tlabel">姓名：</span>
                <span>ssssssssss</span>
              </div>
            </el-col>
            <el-col :span="24" class="mt-2">
              <el-row>
                <el-col :span="12">
                  <div>
                    <span class="tlabel">级别：</span>
                    <span>ssssssssss</span>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div>
                    <span class="tlabel">星级：</span>
                    <span>ssssssssss</span>
                  </div>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="24" class="mt-2">
              <el-row>
                <el-col :span="12">
                  <div>
                    <span class="tlabel">案例数：</span>
                    <span>ssssssssss</span>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div>
                    <span class="tlabel">服务费用：</span>
                    <span>ssssssssss</span>
                  </div>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="24" class="mt-2">
              <el-row>
                <el-col :span="12">
                  <div>
                    <span class="tlabel">联系电话：</span>
                    <span>ssssssssss</span>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div>
                    <span class="tlabel">提成比例：</span>
                    <span>ssssssssss</span>
                  </div>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="24" class="mt-2 ">
              <el-row>
                <el-col :span="12">
                  <div>
                    <span class="tlabel">联系电话：</span>
                    <span>ssssssssss</span>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div>
                    <span class="tlabel">提成比例：</span>
                    <span>ssssssssss</span>
                  </div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-card>
  </el-dialog>
</template>
<script>
export default {
  emits: ["offIt"],
  props: {
    parentData: {},
  },
  data() {
    return {
      tree: [],
      form: {},
    };
  },
  mounted() {},
  methods: {
    //关闭窗口
    async handleClickClose() {
      this.$emit("offIt");
    },
  },
};
</script>
<style scoped>
.el-form .el-form-item {
  margin-bottom: 5px !important;
}
.tlabel {
  width: 80px;
  font-weight: 700;
}
</style>
