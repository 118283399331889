/**
 * axios的封装
 */
import axios from "axios";

// let baseURL = "//wx.empowerheart.com.cn/nodeApi/";
let baseURL = "http://localhost:7001/";

if (process.env.NODE_ENV === "development") {
  baseURL = "http://localhost:7001/";
} else {
  baseURL = "//wx.empowerheart.com.cn/nodeApi/";
}

// defaults config

axios.defaults.baseURL = baseURL;
axios.defaults.timeout = 60 * 1000 * 60;
axios.defaults.headers.common["Authorization"] = "";
axios.defaults.headers.common["Content-Type"] = "application/json;charset=utf8";

const httpInit = pre => {
  let http = axios.create({
    baseURL: baseURL + pre
  });
  //  TODO 优化白名单和请求头设置
  const whiteList = ["kgt-ucenter/saas/api/valid/login", "/api/login"];
  http.interceptors.request.use(
    async config => {
      let token = "";
      if (whiteList.find(item => item === config.url)) {
        token = "";
      } else {
        token = localStorage.getItem("RLTToken");
      }
      Object.assign(config.headers, {
        x_Fang_HD_ssoToken: token
      });
      if (config.method === "delete" || config.method === "get") {
        config.data = config.data || {};
      }

      if (
        config.data &&
        typeof config.data === "object" &&
        !(config.data instanceof Array) &&
        !(config.data instanceof FormData)
      ) {
        //TODO 优化拦截器对参数过滤
        let temp = {};
        let key;
        for (key in config.data) {
          if (
            config.data[key] ||
            config.data[key] === 0 ||
            config.data[key] === ""
          ) {
            temp[key] = config.data[key];
          }
        }
        config.data = temp;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  http.interceptors.response.use(
    res => {
      let json = res && res.data;
      return Promise.resolve(json);
    },
    error => {
      if (error.response) {
        // 请求已发出，但服务器响应的状态码不在 2xx 范围内
        return Promise.reject(error.response.data);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error", error.message);
      }

      return Promise.reject(error);
    }
  );

  return http;
};

/**
 * 处理多条件搜索的请求地址
 *
 * @param {String} prePath 固定url
 * @param {[String]} argNames 参数名称数组
 * @param {*} params 传入的参数
 */
const getFormat = (prePath, argNames, params) => {
  let url = prePath;
  if (argNames.length) {
    argNames.forEach(argName => {
      let arg = params[argName];
      if (arg || arg === 0) {
        arg;
      } else {
        arg = "-";
      }
      url += "/" + arg;
    });
  }
  return url;
};
export { httpInit, getFormat, baseURL };
