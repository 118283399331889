import { httpInit } from "./http";

const http = httpInit("/consulting/setting");


//设置参数配置
export const settingSet = params => http.post(`/set`, params);

//查询配置明细
export const getSettingInfo = params => http.get(`/info`, params);

export const removeSettingInfo = id =>http.delete(`/del/${id}`)
// export const settingSetV2 = params => http.post('/consulting/setting/set')
