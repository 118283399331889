import { httpInit, getFormat } from "./http";

const http = httpInit("/partner");

export const addDistributor = p => http.post(`/info`, p);

export const modifyDistributor = p => http.put(`/info/${p.id}`, p);

export const deleteDistributor = id => http.delete(`/info/${id}`);

export const fetchDistributorsByName = p =>
  http.get(getFormat("/list", ["name", "page", "size"], p));
export const fetchDistributors = p =>
  http.get(getFormat("/list", ["page", "size"], p));

export const uploadExcel = (file, onUploadProgress) => {
  let form = new FormData();
  form.append("upFile", file);
  return http.post(`/export`, form, {
    headers: {
      "Content-Type": "multipart/form-data"
    },
    onUploadProgress
  });
};
// 查询报告列表
export const getReportList = p =>
  http.get(getFormat("/log/page", ["page", "size"], p));

export const getReportInfo = id => http.get(`/log/info/${id}`);
