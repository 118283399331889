<template>
  <div class="login">
    <div class="login-img">
      <!-- <img src="../assets/login/logo_title.png" /> -->
    </div>
    <div class="login-p font-bold">
      <p>鼎心科技后台管理系统</p>
    </div>
    <el-card class="login-form">
      <div class="login-center text-center">
        <!-- <p class="mb-4 text-xl font-normal">账号密码登录</p> -->
        <el-tabs v-model="activeName" stretch>
          <el-tab-pane label="账号密码登录" name="first"></el-tab-pane>
          <!-- <el-tab-pane label="手机号验证码登录" name="second"></el-tab-pane> -->
        </el-tabs>
        <el-input class="mb-2" v-model="params.username"></el-input>
        <el-input
          class="mb-4"
          type="password"
          v-model="params.password"
        ></el-input>
        <el-button
          class="mt-2 login-stmbtn"
          type="primary"
          @click="handleClickLogin()"
          :loading="loading"
          >{{ loading ? "正在登录" : "立即登录" }}</el-button
        >
      </div>
    </el-card>
    <div class="bottom">
<a href="https://beian.miit.gov.cn/">粤ICP备2023090557号</a>
    </div>
  </div>
</template>

<script>
import { login } from "@/api/service";
export default {
  data() {
    return {
      params: {
        // username: "admin",
        // password: "admin@123456!"
        username: "",
        password: ""
      },
      activeName: "first",
      loading: false
    };
  },
  methods: {
    async handleClickLogin() {
      let res = {};
      this.loading = true;
      try {
        res = await login(this.params);
      } catch (error) {
        res.code = 9;
      }
      if (res.code === 0) {
        let now = new Date();
        // save user info & RLTToken
        localStorage.setItem("RLTuser", JSON.stringify(res.data));
        localStorage.setItem("RLTToken", res.data.token);
        localStorage.setItem(
          "RLTTokenExpire",
          now.getTime() + 3 * 60 * 60 * 1000
        );
        localStorage.setItem("menus", JSON.stringify(res.data.menus));
        this.$router.push("/");
      } else {
        this.$message.error("登录失败");
      }
      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.login {
  height: 100vh;
  width: 100vw;
  // background: url("../assets/login/login_bg.jpg") repeat 100%;
  background: rgb(35, 44, 41);
  background-size: 100% 100%;
  padding-top: 80px;
  &-img {
    width: 460px;
    margin: 0 20% 120px auto;
  }
  &-p {
    color: #ffffff;
    font-size: 30px;
    width: 460px;
    margin: 0 10% 20px auto;
  }
  &-form {
    width: 460px;
    margin: 0 10% 0 auto;
  }
  &-center {
    margin: 5px 80px;
  }
  &-stmbtn {
    width: 100%;
    background: #fe9b00;
    border: none;
    &:hover {
      background: #f59003;
    }
  }
  .bottom{
    // display:f ;
    position: fixed;
    bottom: 20px;
    left: 0;
    width: 99vw;
    text-align: center;
    color: #ccc;
    font-size: 14px;
  }
}
</style>
