import { httpInit, baseURL } from "./http";
let http = httpInit("/star-calendar/cur");

export const upload = (file, onUploadProgress) => {
  let form = new FormData();
  form.append("upFile", file);
  return http.post(`/upload`, form, {
    headers: {
      "Content-Type": "multipart/form-data"
    },
    onUploadProgress
  });
};
// 上传封面等 会生成压缩图片
export const uploadThumb = (file, onUploadProgress) => {
  let form = new FormData();
  form.append("upFile", file);
  return http.post(`/upload`, form, {
    headers: {
      "Content-Type": "multipart/form-data"
    },
    onUploadProgress
  });
};
export const uploadConfig = {
  action: baseURL + "/star-calendar/cur/upload",
  name: "upFile",
  headers: {
    "Content-Type": "multipart/form-data"
  }
};
// 上传封面等 会生成压缩图片
export const uploadThumbConfig = {
  action: baseURL + "/star-calendar/cur" + "/upload",
  name: "upFile",
  headers: {
    "Content-Type": "multipart/form-data"
  }
};
