<template>
  <div>
    <el-card class="mb-1">
      <div class="flex justify-between">
        <span>
          <el-input
            class="mr-2"
            v-model="page.name"
            placeholder="用户名称"
            style="width: 120px"
          ></el-input>
          <el-select
            class="mr-2"
            v-model="page.roleId"
            clearable
            placeholder="角色"
          >
            <el-option
              v-for="item in roleList"
              :key="item.id"
              :label="item.roleName"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-button type="primary" @click="findData">搜索</el-button>
        </span>
        <span>
          <el-button type="primary" @click="opAdd">新增用户</el-button>
        </span>
      </div>
    </el-card>
    <el-card>
      <el-table :data="list" style="width: 100%">
        <el-table-column prop="username" label="用户名"> </el-table-column>
        <el-table-column prop="profile.realName" label="姓名">
        </el-table-column>
        <el-table-column prop="type" label="用户类型">
          <template #default="scope">
            {{ scope.row.type == 1 ? "后台客服" : "咨询师" }}
          </template>
        </el-table-column>
        <el-table-column prop="roleName" label="角色"> </el-table-column>
        <el-table-column prop="created" label="注册时间"> </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <div class="flex justify-around cursor-pointer">
              <el-button type="text" size="small" @click="opUpdata(scope.row)"
                >编辑
              </el-button>
              <el-button type="text" size="small" @click="changePass(scope.row)"
                >重置密码
              </el-button>
              <el-button type="text" size="small" @click="userDelete(scope.row)"
                >注销
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex justify-center mt-4">
        <el-pagination
          background
          @current-change="getTableList"
          :current-page.sync="page.pageNo"
          :page-size="page.pageSize"
          layout="prev, pager, next, jumper"
          :total="page.total"
        >
        </el-pagination>
      </div>
    </el-card>
    <AddUser
      v-if="addDialog.show"
      :parentData="addDialog"
      @offIt="offDia"
      :roleList="roleList"
    ></AddUser>
    <InfoUser
      v-if="infoDialog.show"
      :parentData="infoDialog"
      @offIt="offDia"
    ></InfoUser>
  </div>
</template>

<script>
import {
  getAccounts,
  getAccountInfo,
  deleteAccount,
  resetPwd
} from "@/api/service";
import { getRoleList } from "@/api/role";

import AddUser from "../Users/userForm";
import InfoUser from "../Users/infoUser";
export default {
  name: "userList",
  data() {
    return {
      list: [],
      roleList: [],
      user: JSON.parse(localStorage.RLTuser),
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 1,
        name: "",
        roleId: ""
      },
      addDialog: {
        show: false,
        data: {},
        title: ""
      },
      infoDialog: {
        show: false,
        data: {},
        tree: []
      }
    };
  },
  mounted() {
    this.getRole();
  },
  methods: {
    //获取用户列表
    async getTableList() {
      let params = {
        roleId: this.page.roleId == "" ? "-" : this.page.roleId,
        username: this.page.name == "" ? "-" : this.page.name,
        type: 1,
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize
      };
      let res = await getAccounts(params);
      if (res.code === 0) {
        this.list = res.data.records || [];
        this.page.total = res.data.count;

        this.list = this.list.map(item => {
          return Object.assign(item, {
            roleName: this.roleList.find(e => e.id === item.role.id).roleName
          });
        });
      }
    },
    //搜索
    findData() {
      this.page.pageNo = 1;
      this.getTableList();
    },
    //获取角色
    async getRole() {
      let params = {
        conditions: "-"
      };
      let res = await getRoleList(params);
      if (res.code === 0) {
        this.roleList = res.data || [];
        this.roleList = this.roleList.filter(
          item =>
            item.roleName === "系统管理员" ||
            item.roleName === "客服" ||
            item.roleName === "导师" ||
            item.roleName === "渠道商"
        );
      }
      this.getTableList();
    },
    //根据用户id获取详情
    async userInfo(data) {
      let res = await getAccountInfo(data.id);
      if (res.code === 0) {
        console.log(res);
        this.infoDialog.data = res.data;
      }
      console.log(res);
    },
    //打开修改弹窗
    async opUpdata(data) {
      let res = await getAccountInfo(data.id);
      if (res.code === 0) {
        console.log(res);
        this.addDialog = {
          show: true,
          data: res.data,
          title: "修改用户"
        };
      }
    },
    //打开修改密码确认框
    changePass(data) {
      this.$confirm("确认重置用户密码为：Ab12345678 么？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.pwdreset(data.id);
      });
    },
    //修改密码
    async pwdreset(id) {
      let params = {
        id: id
      };
      let res = await resetPwd(params);
      if (res.code === 0) {
        this.$message({
          type: "success",
          message: "重置成功!"
        });
      } else {
        this.$message.error(res.message);
      }
    },
    //删除用户
    async deletefun(data) {
      let delres = await deleteAccount(data.id);
      if (delres.code === 0) {
        this.$message({
          type: "success",
          message: "删除成功!"
        });
        this.getTableList();
      } else {
        this.$message.error(delres.message);
      }
    },
    //删除用户确认
    async userDelete(data) {
      this.$confirm("此操作将永久删除该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.deletefun(data);
      });
    },
    //打开新增弹窗
    async opAdd() {
      this.addDialog = {
        show: true,
        data: {},
        title: "新增用户"
      };
    },
    //关闭窗口
    async offDia(data) {
      this.addDialog.show = false;
      this.infoDialog.show = false;
      if (data) {
        this.getTableList();
      }
    }
  },

  components: { AddUser, InfoUser }
};
</script>

<style>
.el-pagination__jump {
  margin-top: -6px;
}
</style>
