<template>
  <el-card class="cms-banner card card__main">
    <div class="c-search">
      <div class="c-search-params">
        <el-input placeholder="备注" v-model="list.remark"></el-input>
        <el-button @click="pubList(true)">搜索</el-button>
        <el-button
          @click="delMany"
          type="danger"
          :disabled="selectData.length === 0"
          >批量删除</el-button
        >
      </div>
      <div class="c-search-btns">
        <!-- <el-button type="warning">批量导入</el-button> -->
        <el-button type="primary" @click="openDialog" icon="el-icon-plus">
          创建激活码
          <i class="iconfont iconzengjia right"></i>
        </el-button>
      </div>
    </div>
    <p class="mt-2">
      <span v-show="this.selectData && this.selectData.length"
        >已选择 {{ this.selectData.length }} 条数据</span
      >
      <span></span>
    </p>
    <Table
      :data="list.data"
      :cols="list.cols"
      :page="list"
      size="mini"
      v-loading="list.loading"
      :showSelection="true"
      @page="handlePage"
      @current-change="currentChange"
      @size-change="sizeChange"
      @selection-change="handleSelectChange"
    >
      <template #thumb>
        >
        <el-table-column label="小程序码" width="150">
          <template #default="scope">
            <div class="flex flex-row">
              <div>
                <!-- <el-image
                  class="mr-2"
                  style="width: 100px; "
                  fit="contain"
                  :src="scope.row.qrCode"
                ></el-image> -->
                <qrCodeImg
                  :base64="scope.row.qrCode"
                  :remark="scope.row.remark"
                />
              </div>
            </div>
          </template>
        </el-table-column>
      </template>

      <template #action>
        >
        <el-table-column label="操作" width="180">
          <template #default="scope">
            <el-button @click="handleClickRemove(scope.row)" type="text"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </template>
    </Table>
    <el-dialog
      title="新增激活码"
      :visible.sync="dialog.show"
      width="600px"
      center
    >
      <el-form
        :model="dialog.data"
        :rules="rules"
        label-width="90px"
        v-loading="dialog.loading"
      >
        <el-form-item label="服务" required>
          <el-checkbox-group v-model="dialog.data.serviceIds">
            <el-checkbox :label="2" :key="2">亲子报告</el-checkbox>
            <el-checkbox :label="1" :key="1">无限存储</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="生成数量">
          <el-input-number
            v-model="dialog.data.sum"
            :min="1"
            :max="70"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="dialog.data.remark" maxlength="30"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog.show = false">取 消</el-button>
        <el-button
          :loading="dialog.loading"
          type="primary"
          @click="submitDialog(false)"
          >确认</el-button
        >
        <el-button
          :loading="dialog.loading"
          type="primary"
          @click="submitDialog(true)"
          >确认并导出二维码</el-button
        >
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import { addCode, closeCode, fetchCodes, closeCodeByCodes } from "@/api/public";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import Table from "@/components/Table/Table";
import qrCodeImg from "./CodeQrImg.vue";
import dayjs from "dayjs";
export default {
  name: "CodeList",
  components: { Table, qrCodeImg },
  data() {
    return {
      selectData: [],
      list: {
        pageNo: 1,
        pageSize: 10,
        count: 0,
        total: 1,
        remark: "",
        data: [],
        cols: [
          {
            key: "thumb"
          },
          { key: "createTime", label: "创建时间" },
          { key: "state", label: "状态" },
          { key: "serviceIds", label: "服务" },
          { key: "adminName", label: "创建人" },
          { key: "openId", label: "使用用户" },
          {
            key: "remark",
            label: "备注"
          },
          { key: "code", label: "激活码" },

          {
            label: "操作",
            key: "action",
            width: 100
          },
          {}
        ],
        loading: false
      },
      rules: {},
      delDialog: {
        show: false,
        list: [],
        loading: false
      },
      dialog: {
        show: false,
        data: {
          serviceIds: [],
          sum: 1,
          remark: ""
        },
        isExport: true,
        title: "",
        loading: false
      },
      user: {}
    };
  },
  mounted() {
    this.pubList();
    this.user = JSON.parse(localStorage.getItem("RLTuser"));
  },
  methods: {
    handleSelectChange(data) {
      console.log(data);
      this.selectData = data;
    },
    // 批量删除
    async delMany() {
      let data = this.selectData;
      this.$confirm(
        `此操作将永久删除选择的 ${data.length} 项激活码, 是否继续?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          beforeClose: async (action, instance, done) => {
            if (action === "confirm") {
              instance.confirmButtonLoading = true;
              instance.confirmButtonText = "正在删除";
              this.deleteByCode(data.map(v => v.code)).then(() => {
                done();
                instance.confirmButtonLoading = false;
              });
            } else {
              done();
            }
          }
        }
      ).then(() => {});
    },
    async deleteByCode(ids) {
      console.log(ids);
      let res = await closeCodeByCodes(ids);
      if (res.code === 0) {
        this.$message.success("操作成功");
        this.pubList();
      }
      console.log(res);
    },
    async test(data, folderName) {
      console.time("处理二维码");
      let arr = data
        .filter(v => v.qrCode)
        .map(({ qrCode, remark }, i) => this.handleQrCode(qrCode, remark || i));
      console.log(arr);
      let imgDatas = await Promise.all(arr);
      let zip = new JSZip();
      console.timeEnd("处理二维码");
      console.time("生成压缩文件");
      let imgs = [];
      imgDatas.forEach(({ qrCode, remark }) => {
        imgs.push(qrCode);
        var img = zip.folder(folderName || "qrcode");
        img.file(remark + ".jpeg", qrCode.split("data:image/jpeg;base64,")[1], {
          base64: true
        });
      });
      zip.generateAsync({ type: "blob" }).then(content => {
        saveAs(content, `激活码${dayjs().format("YYYY-MM-DD HH:mm")}.zip`);
        console.timeEnd("生成压缩文件");
      });
    },
    handleQrCode(base64, remark) {
      return new Promise((resolve, reject) => {
        let canvas = document.createElement("canvas");
        let img = new Image();
        img.onload = () => {
          const imgW = img.width;
          const imgH = img.height;
          canvas.height = imgH + 40;
          canvas.width = imgW;
          let ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);
          ctx.fillStyle = "#fff";
          ctx.fillRect(0, imgH, imgW, imgH + 40);
          ctx.font = "24px SimSun, Songti SC";
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.fillStyle = "#000";
          ctx.fillText(remark || "", imgW / 2, imgH + 20);
          let url = canvas.toDataURL("image/jpeg", 0.5);
          resolve({
            qrCode: url,
            remark
          });
        };
        img.src = base64;
        img.onerror = e => reject(e);
      });
    },
    //获取列表
    async pubList(isSearch) {
      if (isSearch) {
        this.list.pageNo = 1;
      }
      let params = {
        pageNo: this.list.pageNo,
        pageSize: this.list.pageSize,
        remark: this.list.remark
      };
      this.list.loading = true;
      let res = await fetchCodes(params);
      if (res && res.code === 0 && res.data.records) {
        this.list.data = res.data.records || [];
        this.list.count = res.data.count;
        const stateObj = {
          0: "未使用",
          1: "已使用"
        };
        this.list.data = this.list.data.map(item => {
          return Object.assign(item, {
            state: stateObj[item.state],
            createTime: dayjs(item.createTime).format("YYYY-MM-DD HH:mm"),
            serviceIds: item.serviceIds
              .map(v => (v === 1 ? "无限存储" : "亲子报告"))
              .join(",")
          });
        });
        console.log(this.list.data);
      } else {
        this.list.data = [];
      }
      this.list.loading = false;
    },

    handlePage(v) {
      this.list.pageNo = v;
      this.pubList();
    },
    currentChange(v) {
      this.list.pageNo = v;
      this.pubList();
    },
    sizeChange(v) {
      this.list.pageNo = 1;
      this.list.pageSize = v;
      this.pubList();
    },
    async handleClickRemove(data) {
      try {
        await this.$confirm("确认删除？", "确认信息", {
          distinguishCancelAndClose: true
        });
        let res = await closeCode(data.code);
        if (res && res.code === 0) {
          this.$message.success("操作成功");
          this.pubList(true);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async openDialog() {
      this.dialog = {
        show: true,
        data: { serviceIds: [], sum: 1, remark: "" },
        isExport: true,
        title: "",
        loading: false
      };
    },
    async submitDialog(isExport) {
      this.dialog.loading = true;
      const adminName = this.user.realName;
      let res = await addCode({
        ...this.dialog.data,
        adminName: adminName || ""
      });
      if (res.code === 0) {
        this.$message.success("操作成功");
        this.pubList();
        if (res.data && isExport) {
          await this.test(res.data, this.dialog.data.remark);
        }
        this.dialog.show = false;
      }
      this.dialog.loading = false;
    }
  }
};
</script>

<style>
.cms-banner .el-image-viewer__close {
  color: #fff;
  font-size: 50px;
}
</style>
