<template>
  <el-container class="el-content">
    <el-aside width="200px" v-if="initCompalete">
      <div class="asider-logo">
        <!-- <img class="asider-logo-img" src="@/assets/logo.png" alt="" /> -->
        <h1>鼎心科技后台管理系统</h1>
      </div>
      <Menu />
    </el-aside>
    <el-container v-if="initCompalete">
      <el-header
        style="text-align: left; font-size: 18px"
        class="flex justify-between justify-between items-center"
        height="80px"
      >
        <div class="text-logo flex flex-col">
          <!-- <img src="@/assets/text-logo.png" alt="" />
          <p>CHINA ASSOCIATION OF METROS</p> -->
        </div>
        <div>
          <span class="text-sm mr-4"
            ><i class="el-icon-message-solid"></i
          ></span>
          <span class="text-sm mr-4"><i class="el-icon-s-tools"></i></span>
          <span class="text-sm mr-4">欢迎，{{ user.realName }}</span>
          <el-button type="text" @click="logout">退出登录</el-button>
        </div>
      </el-header>
      <el-main class="el-content-main">
        <div class="mb-4">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item
              v-for="item in path"
              :to="{ path: '/' }"
              :key="item.name"
              >{{ item.name }}</el-breadcrumb-item
            >
          </el-breadcrumb>
        </div>

        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>
<style lang="scss">
.el-header {
  background-color: #fff;
  color: #333;
  box-shadow: 0 2px 0px #f0f1f2;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  .text-logo {
    img {
      width: 230px;
    }
    p {
      font-size: 16px;
      line-height: 1;
    }
  }
}
.el-content {
  height: 100vh;
  background-color: #f0f2f5;
  .el-menu {
    background: #041527;
    border-right: none !important;
  }
  .asider-logo {
    // background-color: #0094bf;
    height: 80px;
    color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center;
    &-img {
      display: inline-block;
      width: 100px;
    }
  }
  .el-menu-item,
  .el-submenu__title {
    // background:#041527 ;
    color: #a7adb4;
    &:hover {
      color: #fff;
      background: none;
    }
  }
  .el-menu-item.is-active {
    background-color: #1890ff;
    color: #fff;
  }
  &-main {
    padding: 10px;
    position: relative;
  }
  .el-aside {
    height: 100vh;
    background: #041527;
  }
}
</style>

<script>
import Menu from "@/components/Layout/Menu";

export default {
  name: "Home",
  components: { Menu },
  data() {
    return {
      menu: [],
      user: {},
      initCompalete: false,
    };
  },
  async created() {
    this.checkPermission();
    this.user = JSON.parse(localStorage.getItem("RLTuser"));
  },
  computed: {
    path() {
      return this.$route.meta.path || [];
    },
  },
  methods: {
    logout() {
      localStorage.removeItem("RLTToken");
      localStorage.removeItem("RLTTokenExpire");
      this.$router.push("/Login");
      this.$message.info("操作成功");
    },
    // 检测权限
    async checkPermission() {
     
      this.initCompalete = true;
    },
  },
};
</script>
