<template>
  <el-card class="cms-banner card card__main">
    <div class="c-search">
      <div class="c-search-params"></div>
      <div class="c-search-btns">
        <!-- <el-button type="warning">批量导入</el-button> -->
        <Uploader @change="handleUpload" />
      </div>
    </div>

    <Table
      :data="list.data"
      :cols="list.cols"
      :page="list"
      v-loading="list.loading"
      :showSelection="false"
      @current-change="handleCurrentChange"
    >
      <template #link> </template>
      <template #action>
        <el-table-column label="操作" width="120">
          <template #default="scope">
            <el-button @click="openDialog(scope.row)" type="text"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </template>
    </Table>

    <el-dialog
      title="报告记录"
      :visible.sync="dialog.show"
      v-if="dialog.show"
      width="600px"
      center
    >
      <div class=" text-center m-4" v-show="download">
        <el-button type="text" @click="downloadReport(download)"
          >点击下载报告文件</el-button
        >
      </div>
      <Table
        v-if="reportInfo && reportInfo.length"
        :data="reportInfo"
        :cols="reportCols"
        v-loading="dialog.loading"
        :showSelection="false"
        :showPage="false"
      >
      </Table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog.show = false">关闭</el-button>
        <!-- <el-button type="primary" @click="submitDialog(false)">确 认</el-button> -->
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import { getReportInfo, getReportList } from "@/api/distributor";
import Uploader from "./DesignDataUploader.vue";
import Table from "@/components/Table/Table.vue";
import dayjs from "dayjs";
export default {
  name: "DesignExport",
  components: {
    Uploader,
    Table
  },
  data() {
    return {
      dialog: {
        show: false,
        title: "",
        loading: false
      },
      download: "",
      list: {
        pageSize: 20,
        pageNo: 1,
        total: 0,
        count: 0,
        data: [],
        cols: [
          { key: "created", label: "报告导出日期" },
          { key: "action", label: "操作" }
        ],
        loading: false
      },
      reportInfo: [],
      reportCols: [
        { key: "name", label: "姓名" },
        { key: "birth", label: "生日" },
        { key: "city", label: "出生地" }
      ]
    };
  },
  created() {
    this.getList(true);
  },
  methods: {
    handleCurrentChange(page) {
      console.log(page);
      this.list.pageNo = page;
      this.getList();
    },
    async handleUpload(url) {
      console.log("main", url);
      this.download = url;
      this.getList(true);
      this.openDialog();
    },
    async downloadReport(url) {
      window.open(url);
    },
    async getList(isSearch) {
      let res = {};
      if (isSearch) this.list.pageNo = 1;
      this.list.loading = true;
      let { pageNo, pageSize } = this.list;
      res = await getReportList({ page: pageNo, size: pageSize });
      if (res.code === 0) {
        this.list.data = res.data.records || [];
        this.list.count = res.data.count;
        this.list.total = res.data.total;
        this.list.count = res.data.count;
      }
      this.list.loading = false;
    },
    async openDialog(data) {
      this.dialog.loading = true;
      this.dialog.show = true;
      if (data) this.download = "";
      this.reportInfo = [];
      if (data && data.id) {
        const { id } = data;
        let res = {};
        try {
          res = await getReportInfo(id);
        } catch (error) {
          res.message = error.message || "解析失败";
        }
        if (res.code === 0) {
          this.reportInfo = res.data.context.map(v => {
            let birth = dayjs(v.birth).format("YYYY-MM-DD HH:mm");
            v.birth = birth;
            return v;
          });
        } else {
          this.$message.error(res.message);
        }
      }
      this.dialog.loading = false;
    }
  }
};
</script>

<style></style>
