<template>
  <el-card class="cms-banner card card__main">
    <div class="c-search">
      <div class="c-search-params"></div>
      <div class="c-search-btns">
        <!-- <el-button type="warning">批量导入</el-button> -->
        <el-button type="primary" @click="openDialog" icon="el-icon-plus">
          新增广告
          <i class="iconfont iconzengjia right"></i>
        </el-button>
      </div>
    </div>
    <Table
      :data="list.data"
      :cols="list.cols"
      :page="list"
      v-loading="list.loading"
      :showSelection="false"
    >
      <template #link>
        >
        <el-table-column label="广告" width="150">
          <template #default="scope">
            <div class="flex flex-row">
              <div>
                <el-image
                  class="mr-2"
                  style="width: 100px; "
                  fit="contain"
                  :src="scope.row.link"
                ></el-image>
              </div>
            </div>
          </template>
        </el-table-column>
      </template>

      <template #action>
        >
        <el-table-column label="操作" width="120">
          <template #default="scope">
            <el-button @click="openDialog(scope.row)" type="text"
              >编辑</el-button
            >
            <el-button @click="handleClickRemove(scope.row)" type="text"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </template>
    </Table>

    <el-dialog
      title="广告管理"
      :visible.sync="dialog.show"
      v-if="dialog.show"
      v-loading="dialog.loading"
      width="600px"
      center
    >
      <el-form :model="dialog.data" :rules="rules" label-width="90px">
        <el-form-item>
          <Uploader
            v-model="dialog.data.link"
            @change="handleChange"
            tips="只能上传图片文件，图片高宽比 4：3,标准尺寸 800*600"
          />
        </el-form-item>
        <el-form-item label="广告标题">
          <el-input v-model="dialog.data.title"></el-input>
        </el-form-item>
        <el-form-item label="广告链接">
          <el-input v-model="dialog.data.jumper"></el-input>
          <p class="mt-2 text-xs text-gray-500">
            当前只支持公众号文章链接
            <a
              class=" underline text-blue-500"
              href="https://jingyan.baidu.com/article/72ee561a0b737de16138df24.html"
              >『如何获取公众号文章地址』</a
            >
          </p>
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model="dialog.data.orderRank" maxlength="30"></el-input>
        </el-form-item>
        <el-form-item label="亲子报告广告标识">
          <el-radio-group v-model="dialog.data.childTag">
            <el-radio :label="0">是</el-radio>
            <el-radio :label="1">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="合图报告广告标识">
          <el-radio-group v-model="dialog.data.doubleTag">
            <el-radio :label="0">是</el-radio>
            <el-radio :label="1">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="星期显示">
          <el-checkbox
            v-model="dialog.data.mon"
            :true-label="1"
            :false-label="0"
            >周一</el-checkbox
          >
          <el-checkbox
            v-model="dialog.data.tue"
            :true-label="1"
            :false-label="0"
            >周二</el-checkbox
          >
          <el-checkbox
            v-model="dialog.data.wed"
            :true-label="1"
            :false-label="0"
            >周三</el-checkbox
          >
          <el-checkbox
            v-model="dialog.data.thu"
            :true-label="1"
            :false-label="0"
            >周四</el-checkbox
          >
          <el-checkbox
            v-model="dialog.data.fri"
            :true-label="1"
            :false-label="0"
            >周五</el-checkbox
          >
          <el-checkbox
            v-model="dialog.data.sat"
            :true-label="1"
            :false-label="0"
            >周六</el-checkbox
          >
          <el-checkbox
            v-model="dialog.data.sun"
            :true-label="1"
            :false-label="0"
            >周日</el-checkbox
          >
        </el-form-item>
        <!-- <el-form-item label="是否限制显示一次">
          <el-radio-group v-model="dialog.data.onceTag">
            <el-radio :label="0">是</el-radio>
            <el-radio :label="1">否</el-radio>
          </el-radio-group>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog.show = false">取 消</el-button>
        <el-button type="primary" @click="submitDialog(false)">确 认</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import { addAdver, modifyAdver, fetchAdvers, deleteAdver } from "@/api/adver";
import Table from "@/components/Table/Table";
import Uploader from "@/components/Uploader/ImageUploader.vue";
// import dayjs from "dayjs";
export default {
  name: "CodeList",
  components: { Table, Uploader },
  data() {
    return {
      list: {
        pageNo: 1,
        pageSize: 10,
        count: 0,
        total: 1,
        data: [],
        cols: [
          { key: "link", label: "广告图片" },
          { key: "jumper", label: "链接" },
          { key: "orderRank", label: "排序" },
          { key: "title", label: "标题" },
          {
            label: "操作",
            key: "action",
            width: 200
          }
        ],
        loading: false
      },
      rules: {},
      dialog: {
        show: false,
        data: {
          serviceIds: [],
          sum: 1,
          remark: ""
        },
        title: ""
      },
      user: {}
    };
  },
  mounted() {
    this.pubList();
    this.user = JSON.parse(localStorage.getItem("RLTuser"));
  },
  methods: {
    handleChange() {},
    //获取列表
    async pubList(isSearch) {
      if (isSearch) {
        this.list.pageNo = 1;
      }
      let params = {
        pageNo: this.list.pageNo,
        pageSize: this.list.pageSize
      };
      this.list.loading = true;
      let res = await fetchAdvers(params);
      if (res && res.code === 0 && res.data) {
        this.list.data = res.data || [];
        console.log(this.list.data);
      } else {
        this.list.data = [];
      }
      this.list.loading = false;
    },

    handlePage(v) {
      this.list.pageNo = v;
      this.pubList();
    },
    currentChange(v) {
      this.list.pageNo = v;
      this.pubList();
    },
    sizeChange(v) {
      this.list.pageNo = 1;
      this.list.pageSize = v;
      this.pubList();
    },
    async handleClickRemove(data) {
      try {
        await this.$confirm("确认删除？", "确认信息", {
          distinguishCancelAndClose: true
        });
        let res = await deleteAdver(data.id);
        if (res && res.code === 0) {
          this.$message.success("操作成功");
          this.pubList(true);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async openDialog(item) {
      this.dialog.show = true;
      if (item.id) {
        this.dialog.data = item;
      } else {
        this.dialog = {
          show: true,
          data: {
            jumper: "",
            link: "",
            orderRank: 1,
            title: "",
            mon: 0,
            onceTag: 0,
            sat: 0,
            sun: 0,
            thu: 0,
            tue: 0,
            wed: 0,
            childTag: 0,
            doubleTag: 0,
            fri: 0
          }
        };
      }
    },
    async submitDialog() {
      let params = this.dialog.data;
      let res = {};
      if (params.id) {
        res = await modifyAdver(params);
      } else {
        res = await addAdver({
          ...this.dialog.data
        });
      }
      if (res.code === 0) {
        this.$message.success("操作成功");
        this.dialog.show = false;
        this.pubList();
      } else {
        this.$message.error(res.message);
      }
    }
  }
};
</script>

<style>
.cms-banner .el-image-viewer__close {
  color: #fff;
  font-size: 50px;
}
</style>
