import { httpInit } from "./http";

const http = httpInit("/consulting/account");

export const login = params => http.post(`/login`, params);

//分页查询账户列表
export const getAccounts = params =>
  http.get(
    `/list/-/${params.username}/${params.roleId}/${params.type}/${params.pageNo}/${params.pageSize}`
  );

//后台注册新账户接口
export const addAccounts = params => http.post(`/info`, params);

//查询账户详情
export const getAccountInfo = id => http.get(`/info/${id}`);

//删除账号
export const deleteAccount = id => http.delete(`/info/${id}`);

//修改账户信息
export const modifyAccount = params => http.put(`/info/${params.id}`, params);

//获取token
export const getAccountToken = id => http.get(`/token/${id}`);

//重置密码
export const resetPwd = params =>
  http.put(`/pwd/reset/${params.id}`, params);

//锁定账号
export const lockUser = params =>
  http.put(`/account/lock/${params.id}`, params);

//解锁账号
export const unlockUser = params =>
  http.put(`/account/unlock/${params.id}`, params);
// 用户修改密码
export const pwdModify = params => http.put(`/pwd/modify`, params);

