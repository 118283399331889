<template>
  <el-card class="cms-banner card card__main">
    <div class="c-search">
      <div class="c-search-params">
        <el-input
          class="text_input"
          v-model="list.payOrder"
          placeholder="订单号"
        ></el-input>
        <el-date-picker
          v-model="list.time"
          type="datetimerange"
          range-separator="至"
          start-placeholder="预约开始日期"
          end-placeholder="预约结束日期"
          value-format="yyyy-MM-DD HH:mm"
        >
        </el-date-picker>
        <el-select v-model="list.state" clearable placeholder="服务状态">
          <el-option
            v-for="(item, index) in stateList"
            :key="index"
            :label="item"
            :value="index"
          >
          </el-option>
        </el-select>
        <el-button
          @click="getLists(true)"
          class="button"
          size="medium"
          type="primary"
        >
          <img
            width="20"
            src="../../../assets/organization/chaxun.png"
            alt=""
          />
        </el-button>
      </div>
      <div class="c-search-btns"></div>
    </div>
    <Table
      :data="list.data"
      :cols="list.cols"
      :page="list"
      v-loading="list.loading"
      @page="handlePage"
      @current-change="currentChange"
      @size-change="sizeChange"
    >
      <!-- <div v-slot:test='1'>123</div> -->

      <template #thumb>
        >
        <el-table-column label="客户">
          <template #default="scope">
            <div>
              {{ scope.row.customer.nickName }}
            </div>
          </template>
        </el-table-column>
      </template>
      <template #press>
        >
        <el-table-column label="预约时间">
          <template #default="scope">
            <span>{{ scope.row.finished }}</span>
          </template>
        </el-table-column>
      </template>
      <template #star>
        >
        <el-table-column label="服务时段">
          <template #default="scope">
            <span
              >{{ scope.row.servDay }}<br />
              {{ scope.row.servBegin }} - {{ scope.row.servEnd }}</span
            >
          </template>
        </el-table-column>
      </template>
      <template #dig>
        >
        <el-table-column label="客户评价">
          <template #default="scope">
            <span>{{ scope.row.cusDig == 1 ? "已评价" : "未评价" }}</span>
          </template>
        </el-table-column>
      </template>
      <template #state>
        >
        <el-table-column label="状态">
          <template #default="scope">
            <span>{{ scope.row.state }}</span>
          </template>
        </el-table-column>
      </template>
      <template #action>
        >
        <el-table-column label="操作" width="180">
          <template #default="scope">
            <el-button type="text" @click="handleClickEdit(scope.row)"
              >详情</el-button
            >
               <el-button type="text" @click="handleClickNote(scope.row)"
              >笔记</el-button
            >
            <el-popconfirm
              v-if="scope.row.state == '待开始'"
              class="pl-2"
              confirm-button-text="确认"
              cancel-button-text="取消"
              icon="el-icon-info"
              icon-color="red"
              title="是否确定开始服务？"
              @confirm="restart(scope.row)"
            >
              <el-button type="text" slot="reference">开始服务</el-button>
            </el-popconfirm>
            <el-popconfirm
              class="pl-2"
              v-if="scope.row.state == '服务开始'"
              confirm-button-text="确认"
              cancel-button-text="取消"
              icon="el-icon-info"
              icon-color="red"
              title="是否确定完成服务？"
              @confirm="handleClickApproval(scope.row)"
            >
              <el-button class="pl-2" type="text" slot="reference"
                >完成服务</el-button
              >
            </el-popconfirm>
          </template>
        </el-table-column>
      </template>
    </Table>

    <el-dialog
      title="提交资料"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      v-loading="dialoading"
      width="400px"
    >
      <el-form
        label-position="left"
        ref="forms"
        :model="article"
        label-width="100px"
      >
        <el-form-item label="上传录音">
          <el-input size="small" v-model="article.audio"></el-input>
        </el-form-item>
        <el-form-item label="上传视频">
          <el-input size="small" v-model="article.video"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="reover()">确认</el-button>
      </span>
    </el-dialog>
    <OrderInfo
      v-if="parentData.show"
      :parentData="parentData"
      @offIt="offDia"
    ></OrderInfo>
  <my-order-note ref="note"></my-order-note>

  </el-card>
</template>

<script>
import {
  getLists,
  flowStart,
  flowFinish,
  getFlowInfo,
} from "@/api/serviceFlow";
import Table from "@/components/Table/Table";
import OrderInfo from "../MyOrder/MyOrderForm.vue";
import MyOrderNote from '@/views/Serves/MyOrder/MyOrderNote.vue';

export default {
  name: "getLists",
  components: { Table, OrderInfo,MyOrderNote, },
  data() {
    return {
      list: {
        pageNo: 1,
        pageSize: 10,
        count: 0,
        payOrder: "",
        time: [],
        state: "",
        type: 2,
        data: [],
        cols: [
          {
            key: "thumb",
          },
          // { key: "press" },
          { key: "star" },
          { key: "state" },
          { key: "dig" },
          {
            label: "操作",
            key: "action",
            width: 200,
          },
        ],
        loading: false,
      },
      stateList: {
        0: "待付款",
        1: "预约成功",
        2: "待开始",
        3: "服务已开始",
        4: "客户爽约",
        5: "咨询师爽约",
        6: "服务已结束",
        8: "已完成",
        9: "已终止",
      },
      dialogVisible: false,
      diaData: {},
      type: 1, //type	类型0=电子期刊1=电子年鉴2=图书 ,示例值(1)
      typeList: [
        {
          id: 1,
          name: "解读师",
        },
        {
          id: 2,
          name: "认证解读师",
        },
        {
          id: 3,
          name: "咨询师",
        },
        {
          id: 4,
          name: "认证咨询师",
        },
      ],
      article: {
        audio: "",
        video: "",
      },
      parentData: {
        show: false,
        data: {},
      },
      dialoading:false
    };
  },
  mounted() {
    this.getLists();
  },

  methods: {
    handleClickNote(e){
      this.$refs.note.openDialog(e.id)
    },
    async getLists(isSearch) {
      this.list.loading = true;
      if (isSearch) {
        this.list.pageNo = 1;
      }
      console.log(this.list.state);
      let params = {
        consultor: "-",
        begin:
          this.list.time && this.list.time.length > 0 ? this.list.time[0] : "-",
        end:
          this.list.time && this.list.time.length > 0 ? this.list.time[1] : "-",
        state: this.list.state == "" ? "-" : this.list.state,
        history: "-",
        cusDig: "-",
        teaDig: "-",
        payOrder: this.list.payOrder == "" ? "-" : this.list.payOrder,
        pageNo: this.list.pageNo,
        pageSize: this.list.pageSize,
      };
      let res = await getLists(params);
      if (res && res.code === 0 && res.data.records) {
        this.list.data = res.data.records;
        this.list.count = res.data.count;
      } else {
        this.list.data = [];
      }
      this.list.loading = false;
    },
    handlePage(v) {
      this.list.pageNo = v;
      this.getLists();
    },
    currentChange(v) {
      this.list.pageNo = v;
      this.getLists();
    },
    sizeChange(v) {
      this.list.pageNo = 1;
      this.list.pageSize = v;
      this.getLists();
    },
    //查询详情
    async handleClickEdit(data) {
      let res = await getFlowInfo(data);
      if (res.code === 0) {
        console.log(res.data);
        this.parentData.data = res.data;
        this.parentData.show = true;
      }
    },
    //关闭窗口
    async offDia() {
      this.parentData.show = false;
    },
    //完成服务提交物料接口
    handleClickApproval(row) {
      this.diaData = row;
      this.article = {
        audio: "",
        video: "",
      };
      this.dialogVisible = true;
    },
    //开始预约
    async restart(data) {
      this.list.loading = true;
      let res = await flowStart(data);
      if (res.code === 0) {
        this.$message.success("操作成功！");
        this.getLists();
      } else {
        this.$message.warning(res.message);
      }
      this.list.loading = false;
    },
    //终止预约
    async reend() {
      console.log(this.article);
    },
    //完成预约
    async reover() {
      this.dialoading = true;
      let params = {
        id: this.diaData.id,
        audioSrc: this.article.audio,
        noteId: this.diaData.id,
        videoSrc: this.article.video,
      };
      let res = await flowFinish(params);
      if (res.code === 0) {
        this.$message.success("操作成功！");
        this.getLists();
        this.dialogVisible = false;
      } else {
        this.$message.warning(res.message);
      }
      this.dialoading = false;
    },
  },
};
</script>

<style>
.cms-banner .el-image-viewer__close {
  color: #fff;
  font-size: 50px;
}
</style>
