<template>
  <div>
    <template v-for="first in list">
      <el-submenu
        v-if="first.children && first.children.length"
        :index="first.title"
        :key="first.title"
      >
        <template #title>{{ first.title }}</template>
        <el-menu-item-group>
          <MenuItem :menuList="first.children" />
        </el-menu-item-group>
      </el-submenu>
      <el-menu-item v-else :index="first.path" :key="first.title">
        <template #title>{{ first.title }}</template>
      </el-menu-item>
    </template>
  </div>
</template>
<script>
export default {
  name: "MenuItem",
  props: {
    menuList: {}
  },
  computed: {
    list() {
      return this.menuList.filter(v => v.permission);
    }
  }
};
</script>

<style></style>
