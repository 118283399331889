import { httpInit } from "./wxhttp";

const http = httpInit("public");

//查询人类图
// {id: type:}
export const getDesign = params => http.post(`/getDesign`, params);

export const addCode = params => http.post("/code/add", params);

export const closeCode = code =>
  http.delete("/code/close", { params: { code } });

export const closeCodeByCodes = codes =>
  http.post("/code/closeByCodes", { codes });

export const fetchCodes = params => http.get("/code/page", { params });

export const fetchCode = code => http.get("/code/info", { params: { code } });
