<template>
  <el-card class="cms-banner card card__main">
    <div class="c-search">
      <div class="c-search-params">
        <el-input
          class="text_input"
          v-model="list.name"
          placeholder="名称"
        ></el-input>
        <el-select v-model="list.roleId" placeholder="咨询师级别">
          <el-option
            v-for="item in roleList"
            :key="item.id"
            :label="item.roleName"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <!-- <el-select v-model="star" placeholder="咨询师星级">
          <el-option
            v-for="item in typeList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select> -->
        <el-button
          @click="pubList(true)"
          class="button"
          size="medium"
          type="primary"
        >
          <img
            width="20"
            src="../../../assets/organization/chaxun.png"
            alt=""
          />
        </el-button>
      </div>
      <div class="c-search-btns">
        <!-- <el-button type="warning">批量导入</el-button> -->
        <el-button type="primary" @click="handleClickEdit" icon="el-icon-plus">
          添加咨询师
          <i class="iconfont iconzengjia right"></i>
        </el-button>
      </div>
    </div>
    <Table
      :data="list.data"
      :cols="list.cols"
      :page="list"
      v-loading="list.loading"
      @page="handlePage"
      @current-change="currentChange"
      @size-change="sizeChange"
    >
      <template #thumb>
        >
        <el-table-column label="姓名" width="150">
          <template #default="scope">
            <div class="flex flex-row">
              <div>
                <el-image
                  class="mr-2"
                  style="width: 60px; height: 90px"
                  fit="contain"
                  :src="scope.row.profile.avatar"
                ></el-image>
              </div>
              <div class="flex flex-wrap content-center">
                <p style="width: 80px">{{ scope.row.username }}</p>
                <div class="el-icon-s-custom ml-2 mt-2"></div>
              </div>
            </div>
          </template>
        </el-table-column>
      </template>
      <template #press>
        >
        <el-table-column label="级别">
          <template #default="scope">
            <span>{{ scope.row.roleName }}</span>
          </template>
        </el-table-column>
      </template>
      <template #star>
        >
        <el-table-column label="星级" width="180">
          <template #default="scope">
            <div class="block">
              <el-rate
                disabled-void-icon-class="el-icon-star-off"
                disabled-void-color="#c0c4cc"
                :icon-classes="iconClasses"
                :colors="['#ff4e4e', '#ff4e4e', '#ff4e4e']"
                v-model="scope.row.profile.level"
                disabled
              >
              </el-rate>
            </div>
          </template>
        </el-table-column>
      </template>
      <template #action>
        >
        <el-table-column label="操作" width="180">
          <template #default="scope">
            <el-button type="text" @click="userInfo(scope.row)">详情</el-button>
            <el-button type="text" @click="handleClickApproval(scope.row)"
              >调级</el-button
            >
            <el-button type="text" @click="handleClickEdit(scope.row, 1)"
              >修改</el-button
            >
            <el-button @click="handleClickRemove(scope.row)" type="text"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </template>
    </Table>
    <AddUser
      v-if="addDialog.show"
      :parentData="addDialog"
      @offIt="offDia"
      :roleList="roleList"
    ></AddUser>
    <InfoUser
      v-if="infoDialog.show"
      :parentData="infoDialog"
      @offIt="offDia"
    ></InfoUser>
    <el-dialog
      title="咨询师调级"
      :visible.sync="dialogVisible"
      v-if="dialogVisible"
      v-loading="dialoading"
      width="300px"
      center
    >
      <el-select
        v-model="changeData.role.id"
        placeholder="请选择"
        style="width: 100%"
      >
        <el-option
          v-for="item in roleList"
          :key="item.id"
          :label="item.roleName"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changeState(false)">取 消</el-button>
        <el-button type="primary" @click="changeState(true)">确 认</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import {
  getAccounts,
  modifyAccount,
  deleteAccount,
  getAccountInfo,
} from "@/api/service";
import { getRoleList } from "@/api/role";
import Table from "@/components/Table/Table";
import AddUser from "../Pubs/PubForm";
import InfoUser from "../Pubs/info";
export default {
  name: "PubList",
  components: { Table, AddUser, InfoUser },
  data() {
    return {
      list: {
        pageNo: 1,
        pageSize: 10,
        count: 0,
        total: 1,
        name: "",
        roleId: "",
        data: [],
        cols: [
          {
            key: "thumb",
          },
          { key: "press" },
          { key: "star" },
          { key: "profile.caseCount", label: "案例总数" },
          { key: "sums", label: "预约总数/已完成/已终止/未完成" },
          { key: "profile.cost", label: "咨询费用" },
          { key: "profile.phone", label: "联系电话" },
          {
            label: "操作",
            key: "action",
            width: 200,
          },
        ],
        loading: false,
      },
      roleList: [],
      dialogVisible: false,
      diaData: {},
      type: 1,
      addDialog: {
        show: false,
        data: {},
        tree: [],
        title: "",
      },
      infoDialog: {
        show: false,
        data: {},
      },
      changeData: {},
      iconClasses: ["el-icon-star-on", "el-icon-star-on", "el-icon-star-on"],
      dialoading: false,
    };
  },
  mounted() {
    this.getRole();
  },
  methods: {
    //获取列表
    async pubList(isSearch) {
      if (isSearch) {
        this.list.pageNo = 1;
      }
      let params = {
        roleId: this.list.roleId == "" ? "-" : this.list.roleId,
        username: this.list.name == "" ? "-" : this.list.name,
        type: 2,
        pageNo: this.list.pageNo,
        pageSize: this.list.pageSize,
      };
      let res = await getAccounts(params);
      if (res && res.code === 0 && res.data.records) {
        this.list.data = res.data.records || [];
        this.list.count = res.data.count;

        this.list.data = this.list.data.map((item) => {
          return Object.assign(item, {
            roleName: this.roleList.find((e) => e.id === item.role.id).roleName,
            sums:
              item.profile.total +
              "/" +
              item.profile.finish +
              "/" +
              item.profile.describe +
              "/" +
              item.profile.alive,
          });
        });
        console.log(this.list.data);
      } else {
        this.list.data = [];
      }
      this.list.loading = false;
    },
    //获取角色
    async getRole() {
      let params = {
        conditions: "-",
      };
      let res = await getRoleList(params);
      if (res.code === 0) {
        this.roleList = res.data || [];
        this.roleList = this.roleList.filter(
          (item) =>
            item.roleName === "解读师" ||
            item.roleName === "认证解读师" ||
            item.roleName === "咨询师" ||
            item.roleName === "认证咨询师"
        );
      }
      this.pubList();
    },
    handlePage(v) {
      this.list.pageNo = v;
      this.pubList();
    },
    currentChange(v) {
      this.list.pageNo = v;
      this.pubList();
    },
    sizeChange(v) {
      this.list.pageNo = 1;
      this.list.pageSize = v;
      this.pubList();
    },
    async handleClickRemove(data) {
      try {
        await this.$confirm("确认删除？", "确认信息", {
          distinguishCancelAndClose: true,
        });
        let res = await deleteAccount(data.id);
        if (res && res.code === 0) {
          this.$message.success("操作成功");
          this.pubList(true);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async handleClickEdit(row, tage) {
      if (tage) {
        let res = await getAccountInfo(row.id);
        if (res.code === 0) {
          console.log(res);
          this.addDialog = {
            show: true,
            data: res.data,
            tree: [],
            title: "修改用户",
          };
        }
      } else {
        this.addDialog = {
          show: true,
          data: {},
          tree: [],
          title: "新增用户",
        };
      }
    },
    //根据用户id获取详情
    async userInfo(data) {
      this.list.loading = true;
      let res = await getAccountInfo(data.id);
      if (res.code === 0) {
        console.log(res);
        this.infoDialog.data = res.data;
        this.infoDialog.show = true;
      }
      this.list.loading = false;
    },
    //关闭窗口
    async offDia(data) {
      this.addDialog.show = false;
      this.infoDialog.show = false;
      if (data) {
        this.pubList();
      }
    },
    handleClickApproval(row) {
      this.changeData = row;
      this.dialogVisible = true;
    },
    async changeState(flag) {
      this.dialoading = true;
      if (flag) {
        let params = {
          id: this.changeData.id,
          roleId: this.changeData.role.id,
          avatar: this.changeData.profile.avatar,
        };
        let res = await modifyAccount(params);
        if (res && res.code === 0) {
          this.$message.success("修改成功");
          this.pubList();
        }
      }
      this.dialoading = false;
      this.dialogVisible = false;
    },
  },
};
</script>

<style>
.cms-banner .el-image-viewer__close {
  color: #fff;
  font-size: 50px;
}
</style>
