/**
 * 菜单树
 * title 菜单名称
 * path 菜单路径
 * chilren 子菜单 支持三级
 * resource 所需的资源权限（资源名称）
 */

export default [
  {
    title: "咨询师管理",
    path: "/PubList",
    modelName: ["系统管理员", "客服"]
  },
  {
    title: "咨询预约",
    path: "/Order",
    modelName: ["系统管理员", "客服", "导师"]
  },
  {
    title: "我的预约",
    path: "/MyOrder",
    modelName: ["解读师", "认证解读师", "咨询师", "认证咨询师"]
  },
  
  {
    title: "渠道商管理",
    path: "/Distributor",
    modelName: ["系统管理员"]
  },
  {
    title: "人类图导出",
    path: "/DesignExport"
  },

  {
    title: "激活码管理",
    path: "/code",
    modelName: ["系统管理员"]
  },
  {
    title: "小程序广告",
    path: "/advert",
    modelName: ["系统管理员"]
  },
  {
    title: "预约设置",
    path: "/Appointment",
    modelName: ["解读师", "认证解读师", "咨询师", "认证咨询师"]
  },
  {
    title: "修改密码",
    path: "/ChangePassword",
    modelName: [
      "系统管理员",
      "客服",
      "导师",
      "解读师",
      "认证解读师",
      "咨询师",
      "认证咨询师"
    ]
  },
  {
    title: "系统管理",
    path: "/sys",
    modelName: ["系统管理员"],
    children: [
      {
        title: "账户管理",
        path: "/Users",
        modelName: ["系统管理员"]
      },
      {
        title: "评价标签设置",
        path: "/ServesSetting",
        modelName: ["系统管理员"]
      },
    ]
  }
];
