<template>
  <div class="image-uploader">
    <el-upload
      action="upload"
      :http-request="uploadImg"
      :show-file-list="false"
      :multiple="false"
      :accept="accept"
    >
      <div class="text-left">
        <el-button :loading="loading" type="primary">{{
          loading ? "正在解析" : "点击上传数据文件"
        }}</el-button>
      </div>
      <div slot="tip" class="el-upload__tip text-right">
        <!-- <el-button type="text" size="small">下载数据模板</el-button> -->
        <a
          href="/excel/design.xlsx"
          class=" text-blue-400"
          download="design.xlsx"
          >下载数据模板
        </a>
      </div>
    </el-upload>
  </div>
</template>

<script>
import { uploadExcel } from "@/api/distributor";
import { Loading } from "element-ui";
export default {
  name: "DesignDataUploader",
  model: {
    event: "change",
    prop: "modelValue"
  },
  props: {
    modelValue: {},
    accept: {
      default: "*"
    }
  },
  data() {
    return {
      loading: false,
      fileData: {
        name: ""
      },
      percent: 0
    };
  },
  methods: {
    async uploadImg(args) {
      let loadingInstance = Loading.service({});
      this.loading = true;
      this.percent = 0;
      const { file } = args;
      this.fileData = file;
      let res = {};
      let fn = uploadExcel;
      try {
        res = await fn(file, e => {
          this.percent = ((e.loaded / e.total) * 100) | 0;
          return args.onProgress({ percent: this.percent });
        });
      } catch (error) {
        res.message = error.message;
      }
      loadingInstance.close();
      this.loading = false;

      if (res.code === 0) {
        let url = res.data;
        this.$emit("change", url);
      } else {
        this.$message.error(res.message || "解析失败");
        this.$emit("error", res.message);
      }
    }
  }
};
</script>

<style lang="scss">
.image-uploader {
  .image-slot {
    font-size: 30px;
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #f5f7fa;
    color: #909399;
  }
}
</style>
