import { httpInit } from "./http";

const http = httpInit("");

export const addAdver = p => http.post(`/adver/info`, p);

export const modifyAdver = p => http.put(`/adver/info/${p.id}`, p);

export const deleteAdver = id => http.delete(`/adver/info/${id}`);

export const fetchAdvers = () => http.get(`/adver/list`);
