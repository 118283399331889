<template>
  <div class="qrcodeImg" ref="main">
    <el-image :src="url" :preview-src-list="srcList"> </el-image>
  </div>
</template>

<script>
export default {
  name: "qrcodeImg",
  props: {
    base64: {},
    remark: {}
  },
  data() {
    return {
      url: "",
      srcList: [this.url]
    };
  },
  watch: {
    base64: {
      handler() {
        this.handleQrCode();
      },
      immediate: true
    }
  },
  mounted() {},
  methods: {
    handleQrCode() {
      let canvas = document.createElement("canvas");
      let img = new Image();
      img.onload = () => {
        const imgW = img.width;
        const imgH = img.height;
        canvas.height = imgH + 40;
        canvas.width = imgW;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        ctx.fillStyle = "#fff";
        ctx.fillRect(0, imgH, imgW, imgH + 40);
        ctx.font = "24px SimSun, Songti SC";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillStyle = "#000";
        ctx.fillText(this.remark || "", imgW / 2, imgH + 20);
        this.url = canvas.toDataURL("image/jpeg", 0.5);
        this.srcList = [this.url];
      };
      if (this.base64) {
        img.src = this.base64;
      } else {
        this.url = "";
      }
    }
  }
};
</script>

<style></style>
