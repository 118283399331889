<template>
  <el-dialog
    width="1000px"
    :visible.sync="parentData.show"
    top="10vh"
    title="预约详情"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div class="info-div divide-y-2 divide-black divide-opacity-25">
      <div class="mb-4">
        <table class="contable" border="1">
          <tr>
            <td>咨询客户：</td>
            <td v-if="infodata.customer && infodata.customer.nickName">
              {{ infodata.customer.nickName }}
            </td>
            <td>咨询师：</td>
            <td v-if="infodata.servicer && infodata.servicer.profile">
              {{ infodata.servicer.profile.realName }}
            </td>
          </tr>
          <tr>
            <td>预约时间：</td>
            <td>
              {{ infodata.servDay }} {{ infodata.servBegin }} -
              {{ infodata.servEnd }}
            </td>
            <td>咨询时间：</td>
            <td>
              {{ infodata.servDay }} {{ infodata.servBegin }} -
              {{ infodata.servEnd }}
            </td>
          </tr>
          <tr>
            <td>预约状态：</td>
            <td>{{ infodata.state }}</td>
            <td>咨询师等级：</td>
            <td>{{ infodata.servicer.profile.level }}</td>
          </tr>
          <tr>
            <td>完成时间：</td>
            <td>
              {{ infodata.servDay }} {{ infodata.servBegin }} -
              {{ infodata.servEnd }}
            </td>
            <td></td>
            <td></td>
          </tr>
        </table>
      </div>
      <hr />
      <div class="rltsrc mt-4 mb-4">
        <p class="text-xl font-black">人类图信息</p>
        <div class="border-1 border-gray-400 flex justify-start">
          <img class="imgsclass" :src="rltdata.src" />
          <div style="width: 450px" class="ml-5">
            <table class="contable" border="1">
              <tr v-for="(tab, index) in rltdata.table[0]" :key="index">
                <td>{{ tab.label }}</td>
                <td>{{ tab.value }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <hr />
      <div class="rltsrc mt-4">
        <p class="text-xl font-black">咨询物料</p>
        <div class="border-1 m-4 border-gray-400 flex justify-around">
          <a class="cursor-pointer" :href="infodata.videoSrc" target="_bank"
            >下载视频</a
          >
          <a class="cursor-pointer" :href="infodata.audioSrc" target="_bank"
            >下载音频</a
          >
        </div>
      </div>
      <hr />
      <div class="rltsrc mt-4" v-if="infodata.digs">
        <p class="text-xl font-black">客户评价</p>
        <div
          class="border-1 m-4 border-gray-400"
          v-for="items in infodata.digs"
          :key="items.id"
        >
          <div v-if="items.type == '客户点评'">
            <span>评分：{{ items.credit }}</span>
            <span class="ml-4">内容：{{ items.intro }}</span>
          </div>
        </div>
      </div>
      <hr />
      <div class="rltsrc mt-4" v-if="infodata.digs">
        <p class="text-xl font-black">导师评价</p>
        <div
          class="border-1 m-4 border-gray-400"
          v-for="items in infodata.digs"
          :key="items.id"
        >
          <div v-if="items.type == '导师点评'">
            <span>评分：{{ items.credit }}</span>
            <span class="ml-4">内容：{{ items.intro }}</span>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
// import { getBackInfo } from "@/api/serviceFlow";
import { getDesign } from "@/api/public";
export default {
  components: {},
  emits: ["offIt"],
  props: ["parentData"],
  data() {
    return {
      loading: false,
      infodata: {},
      rltdata: {},
    };
  },
  mounted() {
    this.infodata = this.parentData.data;
    this.getRLT();
  },
  methods: {
    //获取人类图信息
    async getRLT() {
      let params = {
        id: this.parentData.data.history.id,
        token: localStorage.RLTToken,
      };
      // let res = await getBackInfo(params);
      // if (res.code == 0) {
      //   console.log(res);
      // }
      let wxres = await getDesign(params);
      if (wxres.code == 0) {
        console.log(wxres);
        this.rltdata = wxres.data;
      }
    },
    //关闭窗口
    handleClickClose() {
      this.$emit("offIt");
    },
  },
};
</script>
<style lang="scss" scoped >
.el-form .el-form-item {
  margin-bottom: 15px !important;
}
.info-div {
  padding: 20px;
  width: 100%;
}

.contable tr {
  width: 100%;
  height: 48px;
  display: block;
} /* 双行颜色 */
.contable tr:nth-child(2n) {
  background: #e8e8e8;
}
.contable tr td:nth-child(2n) {
  width: 466px;
}
.contable tr td:nth-child(2n-1) {
  width: 123px;
}
.contable tr td {
  padding-left: 5px;
  line-height: 48px;
  text-align: left;
  height: 48px;
  border: 1px solid rgb(219, 219, 219);
}
.cover {
  ::v-deep {
    .el-upload {
      width: 100%;
      text-align: left;
    }
    .el-image {
      min-width: 200px;
    }
    .image-slot {
      min-height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #efefef;
      color: #666;
    }
  }
}
.imgsclass {
  width: 450px;
}
</style>
