import Users from "../views/System/Users/Users.vue";
import Serves from "../views/System/Serves/Serves.vue";
import Appointment from "../views/MySetting/Appointment/Index.vue";
import ChangePassword from "../views/MySetting/ChangePassword/Index.vue";
const systemRoutes = [

  {
    path: "Users",
    name: "Users",
    component: Users,
    meta: {
      title: "用户管理",
      path: [{
        name: "管理后台"
      }, {
        name: "系统管理"
      }, {
        name: "用户管理"
      }]
    }
  },
  {
    path: "ServesSetting",
    name: "ServesSetting",
    component: Serves,
    meta: {
      title: "预约设置",
      path: [{
        name: "管理后台"
      }, {
        name: "系统管理"
      }, {
        name: "预约设置"
      }]
    }
  },
  {
    path: "Appointment",
    name: "Appointment",
    component: Appointment,
    meta: {
      title: "预约设置",
      path: [{
        name: "管理后台"
      }, {
        name: "我的设置"
      }, {
        name: "预约设置"
      }]
    }
  },
  {
    path: "ChangePassword",
    name: "ChangePassword",
    component: ChangePassword,
    meta: {
      title: "修改密码",
      path: [{
        name: "管理后台"
      }, {
        name: "我的设置"
      }, {
        name: "修改密码"
      }]
    }
  }
];
export default systemRoutes;