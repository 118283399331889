// 渠道商
<template>
  <el-card class="cms-banner card card__main">
    <div class="c-search">
      <div class="c-search-params"></div>
      <div class="c-search-btns">
        <!-- <el-button type="warning">批量导入</el-button> -->
        <el-button type="primary" @click="openDialog" icon="el-icon-plus">
          新增渠道商
          <i class="iconfont iconzengjia right"></i>
        </el-button>
      </div>
    </div>
    <Table
      :data="list.data"
      :cols="list.cols"
      :page="list"
      v-loading="list.loading"
      :showSelection="false"
    >
      <template #link> </template>
      <template #action>
        <el-table-column label="操作" width="120">
          <template #default="scope">
            <el-button @click="openDialog(scope.row)" type="text"
              >编辑</el-button
            >
            <el-button @click="handleClickRemove(scope.row)" type="text"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </template>
    </Table>

    <el-dialog
      title="渠道商管理"
      :visible.sync="dialog.show"
      v-if="dialog.show"
      v-loading="dialog.loading"
      width="600px"
      center
    >
      <el-form :model="dialog.data" :rules="rules" label-width="120px">
        <el-form-item label="渠道商名称" required>
          <el-input v-model="dialog.data.name"></el-input>
        </el-form-item>

        <el-form-item label="渠道商等级" required>
          <!-- <el-input v-model="dialog.data.level"></el-input> -->
          <el-select v-model="dialog.data.level">
            <el-option
              v-for="i in 10"
              :key="i"
              :value="i"
              :label="i"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="渠道商联系电话">
          <el-input v-model="dialog.data.phone"></el-input>
        </el-form-item>

        <el-form-item label="通讯地址">
          <el-input v-model="dialog.data.address"></el-input>
        </el-form-item>
        <el-form-item label="邮编">
          <el-input v-model="dialog.data.zipCode"></el-input>
        </el-form-item>
        <el-form-item label="电子邮箱">
          <el-input v-model="dialog.data.email"></el-input>
        </el-form-item>
        <el-form-item label="联系人姓名 ">
          <el-input v-model="dialog.data.contactor"></el-input>
        </el-form-item>
        <el-form-item label="联系人电话 ">
          <el-input v-model="dialog.data.cphone"></el-input>
        </el-form-item>
        <el-form-item label="联系人职位头衔 ">
          <el-input v-model="dialog.data.chead"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog.show = false">取 消</el-button>
        <el-button type="primary" @click="submitDialog(false)">确 认</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import {
  addDistributor,
  deleteDistributor,
  fetchDistributors,
  fetchDistributorsByName,
  modifyDistributor
} from "@/api/distributor";
import Table from "@/components/Table/Table";
export default {
  name: "Distributor",
  components: {
    Table
  },
  data() {
    return {
      list: {
        pageNo: 1,
        pageSize: 10,
        name: "",
        total: 0,
        data: [],
        cols: [
          { key: "name", label: "名称" },
          { key: "level", label: "等级" },
          { key: "phone", label: "渠道联系电话" },
          { key: "address", label: "通讯地址" },
          { key: "email", label: "电子邮箱" },
          { key: "contactor", label: "联系人" },
          { key: "cphone", label: "联系人电话" },
          { key: "chead", label: "联系人职位" },
          {
            label: "操作",
            key: "action",
            width: 200
          }
        ]
      },
      dialog: {
        show: false,
        data: {},
        loading: false
      },
      rules: {}
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList(isSearch) {
      const { pageNo, pageSize } = this.list;
      Object.assign(this.list, { page: pageNo, size: pageSize });
      if (isSearch) this.list.page = 1;
      let res = {};
      if (this.list.name) {
        res = await fetchDistributorsByName(this.list);
      } else {
        res = await fetchDistributors(this.list);
      }
      if (res.code === 0 && res.data.records) {
        this.list.data = res.data.records;
        this.list.total = res.data.total;
        this.list.count = res.data.count;
      }
      console.log(res);
    },
    openDialog(data) {
      if (data.id) {
        this.dialog.data = Object.assign({}, data);
      } else {
        this.dialog.data = {};
      }
      this.dialog.show = true;
    },
    async submitDialog() {
      let params = this.dialog.data;
      let res = {};
      try {
        if (params.id) {
          res = await modifyDistributor(params);
        } else {
          res = await addDistributor(params);
        }
      } catch (error) {
        res.message = error.message || "操作失败";
      }

      if (res.code === 0) {
        this.$message.success("操作成功");
        this.getList(true);
        this.dialog.show = false;
      } else {
        this.$message.error(res.message);
      }
    },
    async handleClickRemove(data) {
      try {
        await this.$confirm("确认删除？", "确认信息", {
          distinguishCancelAndClose: true
        });
        let res = await deleteDistributor(data.id);
        if (res && res.code === 0) {
          this.$message.success("操作成功");
          this.getList(true);
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>

<style></style>
