<script>
/**
 * 通用表格组件
 * @author he
 * @description 在 Element 基础表格上进行的统一封装，支持分页、loading、多选功能。 示例查看 src/Article/Banner/BannerList
 * @param cols <Array> 表头 结构为 {key:String, label:String} key 为 data 中数据字段， label 为表头显示的文字
 * @param data <Array> 表格数据
 * @param showPage <boolean> 是否显示分页组件
 * @param page <Object> 分页数据 {pageSize:Number, pageNo:Number, count:Number}
 * @param loading <Boolean> 是否显示 Loading
 * @param showSelection <Boolean> 是否显示多选列
 * @event selection-change 选中列发生改变，具体参照 Element-plus 示例
 * @event current-change pageNo 改变，具体参照 Element-plus 示例
 * @event size-change pageSize 改变，具体参照 Element-plus 示例
 */

export default {
  name: "CusTable",
  components: {},
  props: {
    // 表头
    cols: {
      type: Array
    },
    // 表格数据
    data: {
      type: Array
    },
    /**
     * 翻页数据
     */
    page: {
      type: Object
    },
    loading: {
      type: Boolean
    },
    size: {
      type: String
      // default: "mini"
    },
    /**
     * 是否显示左侧多选
     */
    showSelection: {
      type: Boolean,
      default: true
    },
    showPage: {
      type: Boolean,
      default: true
    }
  },
  created() {},
  watch: {
    cols: {
      handler(v) {
        this.initCols(v);
      }
    }
  },
  mounted() {
    this.initCols(this.cols);
  },
  data() {
    return {
      colAry: []
    };
  },
  methods: {
    initCols() {
      this.cols.forEach(v => {
        this.colAry.push(
          Object.assign(
            {
              _key: v.key,
              _slot: this.$slots[v.key]
            },
            v
          )
        );
      });
    },
    //   选择项发生改变时
    handleSelectionChange(val) {
      this.$emit("selection-change", val);
    },
    handleCurrentChange(e) {
      this.$emit("current-change", e);
    },
    handleSizeChange(e) {
      this.$emit("size-change", e);
    }
  },
  render() {
    let showSelection = () => {
      if (this.showSelection) {
        return <el-table-column type="selection" width="55"></el-table-column>;
      } else {
        return <div></div>;
      }
    };
    let showPageView = () => {
      if (this.showPage) {
        return (
          <el-pagination
            layout="prev, pager, next,sizes,total"
            total={this.page.count}
            page-size={this.page.pageSize}
            current-page={this.page.pageNo}
            on-current-change={this.handleCurrentChange}
            on-size-change={this.handleSizeChange}
            page-sizes={[this.page.pageSize]}
          ></el-pagination>
        );
      } else {
        return "";
      }
    };
    return (
      <div>
        <el-table
          data={this.data || []}
          border={false}
          size={this.size}
          on-selection-change={this.handleSelectionChange}
        >
          {showSelection()}
          {this.colAry.map(item => {
            if (item._slot) {
              return [this.$slots[item._key]];
            } else {
              return (
                <el-table-column
                  prop={item.key}
                  key={item.key}
                  label={item.label}
                  width={item.width || "auto"}
                  show-overflow-tooltip={item.width && true}
                ></el-table-column>
              );
            }
          })}
        </el-table>

        <div class="m-2 page flex justify-center">{showPageView()}</div>
      </div>
    );
  }
};
</script>

<style></style>
