<template>
  <div>
    <CodeQrImg />
    <div v-if="userRole">
      <el-card style="height: 440px" class="mb-2">
        <div id="Passenger" style="width: 100%; height: 420px"></div>
      </el-card>
      <!-- <div class="flex justify-between">
        <el-card style="width: 49%; height: 400px">
          <div id="user" style="width: 100%; height: 400px"></div>
        </el-card>
        <el-card style="width: 50%; height: 400px">
          <div id="service" style="width: 100%; height: 400px"></div>
        </el-card>
      </div> -->
    </div>
    <div v-if="!userRole">
      <el-card>
        <div class="flex justify-between" style="height: 570px">
          <div style="width: 70%; height: 100%">
            <FullCalendar
              style="width: 100%; height: 100%"
              :options="calendarOptions"
              locale="zh-cn"
            />
          </div>
          <div
            style="
              width: 30%;
              background: rgb(68 67 67);
              color: #fff;
              height: 100%;
            "
            class="ml-2 rounded p-2"
          >
            <h1 class="font-extrabold">今日预约</h1>
            <div class="mt-2 ml-6 overflow-auto" style="height: 90%">
              <div v-if="infodata.length > 0">
                <p class="mb-2" v-for="(item, index) of infodata" :key="index">
                  <span class="mr-2"
                    >{{ item.servBegin }}-{{ item.servEnd }}</span
                  >
                  <span class="font-extrabold"
                    >{{ item.customer.nickName }}的预约</span
                  >
                </p>
              </div>
              <div v-else>暂无</div>
            </div>
          </div>
        </div>
        <div
          class="flex justify-around flex-wrap content-center"
          style="color: #fff; height: 200px"
        >
          <div
            class="text-center flex flex-wrap content-center rounded"
            style="
              width: 100px;
              height: 100px;
              background: #eeeded;
              color: #595656;
            "
          >
            <span class="text-5xl w-full text-center">150</span>
            <span class="text-base w-full text-center font-extrabold"
              >预约总数</span
            >
          </div>
          <div
            class="
              bg-green-500
              text-center
              flex flex-wrap
              content-center
              rounded
            "
            style="width: 100px; height: 100px; background: #60c2f7"
          >
            <span class="text-5xl w-full text-center">150</span>
            <span class="text-base w-full text-center font-extrabold"
              >已完成</span
            >
          </div>
          <div
            class="
              bg-green-500
              text-center
              flex flex-wrap
              content-center
              rounded
            "
            style="width: 100px; height: 100px; background: #fd7c16"
          >
            <span class="text-5xl w-full text-center">150</span>
            <span class="text-base w-full text-center font-extrabold"
              >待确认</span
            >
          </div>
          <div
            class="
              bg-blue-500
              text-center
              flex flex-wrap
              content-center
              rounded
            "
            style="width: 100px; height: 100px; background: #dd68ff"
          >
            <span class="text-5xl w-full text-center">150</span>
            <span class="text-base w-full text-center font-extrabold"
              >客户未到</span
            >
          </div>
          <div
            class="
              bg-purple-600
              text-center
              flex flex-wrap
              content-center
              rounded
            "
            style="width: 100px; height: 100px; background: #fdc632"
          >
            <span class="text-5xl w-full text-center">150</span>
            <span class="text-base w-full text-center font-extrabold"
              >我未到</span
            >
          </div>
          <div
            class="
              bg-indigo-500
              text-center
              flex flex-wrap
              content-center
              rounded
            "
            style="width: 100px; height: 100px; background: #ff0000"
          >
            <span class="text-5xl w-full text-center">150</span>
            <span class="text-base w-full text-center font-extrabold"
              >已终止</span
            >
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import "@fullcalendar/core/locales/zh-cn";
import { getLists } from "@/api/serviceFlow";
import CodeQrImg from "./CodeQrImg.vue";
// import dayjs from "dayjs";
// import calenderFormate from "../utils/calendar"; //农历计算方法
// import { formatDate } from "../utils/index"; //将日期对象转换成 2020-01-01 格式的日期

export default {
  name: "dashboard",
  // components: {
  // },
  data() {
    return {
      list: [],
      user: JSON.parse(localStorage.RLTuser),
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 1,
        name: ""
      },
      addDialog: {
        show: false,
        data: {},
        tree: [],
        title: ""
      },
      infoDialog: {
        show: false,
        data: {},
        tree: []
      },
      userRole: true,
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: "dayGridMonth",
        buttonText: {
          today: "今天"
        },
        dateClick: this.handleDateClick,
        locale: "zh-cn",
        events: []
      },
      infodata: []
    };
  },
  mounted() {
    let roleName = JSON.parse(localStorage.RLTuser).roleName;
    if (roleName == "系统管理员" || roleName == "客服" || roleName == "导师") {
      this.userRole = true;
    } else {
      this.userRole = false;
    }
    if (this.userRole) {
      // this.getEcharts();
      // this.getUser();
      // this.getService();
    } else {
      this.getdata();
    }
  },
  methods: {
    //获取预约信息
    async getdata() {
      let id = JSON.parse(localStorage.RLTuser).id;
      let params = {
        consultor: id,
        // begin: dayjs().format("YYYY-MM-DD") + " 00:00",
        // end: dayjs().format("YYYY-MM-DD") + " 23:59",
        // consultor: 14,
        begin: "-",
        end: "-",
        state: "-",
        history: "-",
        cusDig: "-",
        teaDig: "-",
        payOrder: "-",
        pageNo: 1,
        pageSize: 20
      };
      let res = await getLists(params);
      if (res && res.code === 0 && res.data.records) {
        this.infodata = res.data.records;
        if (this.infodata.length > 0) {
          this.calendarOptions.events = [];
          this.infodata.forEach(e => {
            this.calendarOptions.events.push({
              title: e.customer.nickName + "   " + e.servBegin,
              date: e.servDay
            });
          });
        }
      } else {
        this.infodata = [];
      }
    },
    //日历点击事件
    handleDateClick(arg) {
      console.log(arg);
    },
    //绘制echarts图
    getEcharts() {
      var chartDom = document.getElementById("Passenger");
      var myChart = echarts.init(chartDom);
      var option;

      var base = +new Date(1988, 9, 3);
      var oneDay = 24 * 3600 * 1000;

      var data = [[base, Math.random() * 300]];

      for (var i = 1; i < 20000; i++) {
        var now = new Date((base += oneDay));
        data.push([
          [now.getFullYear(), now.getMonth() + 1, now.getDate()].join("/"),
          Math.round((Math.random() + 1) * 20 + data[i - 1][1])
        ]);
      }

      option = {
        tooltip: {
          trigger: "axis",
          position: function(pt) {
            return [pt[0], "10%"];
          }
        },
        title: {
          left: "left",
          text: "客流统计"
        },
        xAxis: {
          type: "time",
          boundaryGap: false
        },
        yAxis: {
          type: "value",
          boundaryGap: [0, "100%"]
        },
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 20
          },
          {
            start: 0,
            end: 20
          }
        ],
        series: [
          {
            name: "模拟数据",
            type: "line",
            smooth: true,
            symbol: "none",
            areaStyle: {},
            data: data
          }
        ]
      };

      option && myChart.setOption(option);
    },
    getUser() {
      var chartDom = document.getElementById("user");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        tooltip: {
          trigger: "item"
        },
        legend: {
          top: "5%",
          left: "center"
        },
        title: {
          left: "left",
          text: "用户分析"
        },
        series: [
          {
            name: "访问来源",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center"
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "40",
                fontWeight: "bold"
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 1048, name: "搜索引擎" },
              { value: 735, name: "直接访问" },
              { value: 580, name: "邮件营销" },
              { value: 484, name: "联盟广告" },
              { value: 300, name: "视频广告" }
            ]
          }
        ]
      };

      option && myChart.setOption(option);
    },
    getService() {
      var chartDom = document.getElementById("service");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        tooltip: {
          trigger: "item"
        },
        legend: {
          top: "5%",
          left: "center"
        },
        title: {
          left: "left",
          text: "服务分析"
        },
        series: [
          {
            name: "访问来源",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center"
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "40",
                fontWeight: "bold"
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 1048, name: "搜索引擎" },
              { value: 735, name: "直接访问" },
              { value: 580, name: "邮件营销" },
              { value: 484, name: "联盟广告" },
              { value: 300, name: "视频广告" }
            ]
          }
        ]
      };

      option && myChart.setOption(option);
    }
  },

  components: { FullCalendar, CodeQrImg }
};
</script>

<style lang="scss" scoped>
.el-pagination__jump {
  margin-top: -6px;
}
.fc {
  height: 100%;
}
.fc-daygrid-event {
  .fc-daygrid-event-dot {
    display: none;
  }
  &.cal {
    padding-left: 5px;
    color: #333;
    border-radius: 0;
    border: none;
    border-left: 2px solid #5289ff;
    background-color: #eef3ff;
    .fc-event-title {
      color: #333;
    }
  }
  &.inv {
    padding-left: 5px;
    color: #333;
    border-radius: 0;
    border: none;
    border-left: 2px solid #ffa327;
    background-color: #fff6e9;
    .fc-event-title {
      color: #333;
    }
  }
}
.fc-timegrid-event {
  .fc-daygrid-event-dot {
    display: none;
  }
  &.cal {
    padding-left: 5px;
    color: #333;
    border-radius: 0;
    border: none;
    border-left: 2px solid #5289ff;
    background-color: #eef3ff;
    .fc-event-title {
      color: #333;
    }
  }
  &.inv {
    padding-left: 5px;
    color: #333;
    border-radius: 0;
    border: none;
    border-left: 2px solid #ffa327;
    background-color: #fff6e9;
    .fc-event-title {
      color: #333;
    }
  }
}
.fc .fc-toolbar-title {
  display: inline-block;
  vertical-align: middle;
}
.fc-theme-standard td {
  border-top-color: transparent;
}
</style>
