import { httpInit } from "./http";

const http = httpInit("/consult/service/note");


// 咨询师查询指定案例的笔记列表
export const getNoteList = id =>http.get(`/list/${id}`);

export const deleteNote = id =>http.delete(`/info/${id}`);

export const addNote = params =>http.post(`/info`,params);